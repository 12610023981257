<template>
  <v-app full-height class="bg-tertiary" >
    <v-system-bar density="compact" color="error" v-if="app_environment != 'production'">
      {{now.format('DD/MM HH:mm:ss')}}<span v-if="is_admin" class="ml-1">- ADMIN</span>
      <v-spacer></v-spacer>
      <span class="hidden-xs">{{root_api}} - </span>{{app_environment}}
    </v-system-bar>
      <component :is="this.$route.meta.layout">
          <router-view />
      </component>
  </v-app>
</template>

<script>

import axios from "axios";
import moment from "moment";
import {SUPPORTED_LANGUAGES} from './locales/constants'
import i18n from "@/i18n";
import store from "@/store";
import router from "@/router";

export default {
  name: 'App',

  data: () => ({
    moment: moment,
    axios: axios,
    now: null,
    app_name: process.env.VUE_APP_NAME,
    app_version: process.env.VUE_APP_VERSION,
    app_environment: process.env.NODE_ENV,
    root_api: process.env.VUE_APP_ROOT_API,
    scroll_at_top: true,
    models: null,
    constants: null,
    invariants: null,
    fields_from_rts: null,
    past_statuses: ['waiting_for_start', 'live', 'finished_provisional', 'finished_official'],
    statuses: {
      finished_statuses: ['finished_provisional', 'finished_official'],
      live_statuses: ['live', 'finished_provisional', 'finished_official'],
      live_statuses_extra: ['waiting_for_start', 'live', 'finished_provisional', 'finished_official'],
      next_statuses: [],
    },
  }),

  computed: {
    is_admin() {
      return this.$store.getters.is_admin
    },
    is_timer() {
      return this.$store.getters.is_timer
    },
    is_cm() {
      return this.$store.getters.is_cm
    },
    name() {
      return this.$store.getters.name
    },
    is_authenticated() {
      return this.$store.getters.is_authenticated
    },
    token() {
      return this.$store.getters.token
    },
    roles() {
      return this.$store.getters.roles
    },
    units() {
      return this.$store.getters.units
    },
    language() {
      return this.$store.getters.language
    },
    autoload() {
      return this.$store.getters.autoload
    },
    validation_codes() {
      return this.$store.getters.validation_codes
    }
  },

  created() {
    this.$store.commit('changeLang', {new_lang: 'es'})  // Force to spanish anyway
    this.$root.$i18n.locale = store.state.language
    moment.locale(this.$root.$i18n.locale)

    this.now = moment().utc(true)
    setInterval(() => {
      this.now = moment().utc(true)
    }, 1000)

    // this.axios.get(`${process.env.VUE_APP_ROOT_API}/models`).then((response) => {
    //   this.models = response.data
    // }).catch((error) => {
    //   console.error(error)
    // })

    this.axios.get(`${process.env.VUE_APP_ROOT_API}/constants`).then((response) => {
      this.constants = response.data
      let constants_ = {}
      // Object.keys(this.constants).forEach(k => {
      //   constants_[k] = {}
      //   this.constants[k].forEach(c => {
      //     constants_[k][c] = this.$t(`constants.${k}.${c}`)
      //   })
      // })
      // console.log(constants_)
    }).catch((error) => {
      console.error(error)
    })

    this.axios.get(`${process.env.VUE_APP_ROOT_API}/invariants`).then((response) => {
      this.invariants = response.data
    }).catch((error) => {
      console.error(error)
    })

    this.axios.get(`${process.env.VUE_APP_ROOT_API}/fields_from_rts`).then((response) => {
      this.fields_from_rts = response.data
    }).catch((error) => {
      console.error(error)
    })

    // This will embed the authentication token in each request (if available)
    this.axios.interceptors.request.use((config) => {
      if (this.is_authenticated) {
        config.headers.authorization = this.token;
      }
      return config;
    })
  },


  methods: {

    parse_time_from_seconds(t) {
      let duration = this.moment.duration({seconds: parseInt(t)})
      return `${Math.floor(duration.asHours())}:${String(duration.minutes()).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`
    },

    parse_elevation(e, unit_text=true) {
      let units = this.$root.units
      if ( units == 'imperial' ) {
        e = e * 3.2808399
      }
      if ( unit_text ) {
        e = parseInt(e) + ((units == 'metric') ? 'm+' : 'ft+')
      }
      return e
    },

    parse_distance(d, decimal=true, unit_text=false, unit_text_abr=true, unit_text_sep=false) {
      let units = this.$root.units
      let total = d / 1000
      if ( units == 'imperial' ) {
        total = total * 0.621371192
      }
      if ( decimal ) {
        total = total.toFixed(1)
      } else {
        total = parseInt(total)
      }
      if ( unit_text ) {
        total = total + ((unit_text_sep) ? ' ' : '')
        if ( unit_text_abr ) {
          total = total + ((units == 'metric') ? 'km' : 'mi')
        } else {
          total = total + this.$t(`units.distance.${units}`)
        }
      }
      return total
    },

    get_speed_and_units(sport, distance, time) {
      let units_c = this.$root.units
      if ( units_c == 'imperial' ) {
        distance = distance * 0.621371192
      }
      let units = ''
      let speed = 0
      let modifier = 1
      if ( ['cycling', 'btt'].includes(sport) ) {
        modifier = 3600 / 1000
        speed = (distance) / (time) * modifier
        speed = speed.toFixed(2)
        units = (units_c == 'metric') ? 'km/h' : 'mi/h'
      } else if ( ['swimming', 'open_waters'].includes(sport) ) {
        modifier = 1000 / 100
        speed = time / distance * modifer
        speed = this.moment.duration({minutes: speed})
        speed = `${String(speed.minutes())}:${String(speed.seconds()).padStart(2, '0')}`
        units = 'min/100m'
      } else {
        modifier = 1000 / 60
        speed = time / distance * modifier
        speed = this.moment.duration({minutes: speed})
        speed = `${String(speed.minutes())}′${String(speed.seconds()).padStart(2, '0')}″`
        units = (units_c == 'metric') ? '/km' : '/mi'
      }

      return `${speed}${units}`
    },

    get_icon_from_sport(sport) {
      if ( sport == 'running' ) return 'fa-solid fa-person-running'
      if ( sport == 'walking' ) return 'fa-solid fa-person-walking'
      if ( sport == 'trail_running' ) return 'fa-solid fa-person-running'
      if ( sport == 'hiking' ) return 'fa-solid fa-person-hiking'
      if ( sport == 'time_trial_climb' ) return 'fa-solid fa-running'
      if ( sport == 'cycling' ) return 'fa-solid fa-person-biking'
      if ( sport == 'btt' ) return 'fa-solid fa-person-biking'
      if ( sport == 'stages' ) return 'fa-solid fa-route'
      // if ( sport == 'biathlon' ) return 'fa-solid fa-user'
      // if ( sport == 'triathlon' ) return 'fa-solid fa-user'
      if ( sport == 'swimming' ) return 'fa-solid fa-person-swimming'
      if ( sport == 'open_waters' ) return 'fa-solid fa-water'
      if ( sport == 'relays' ) return 'fa-solid fa-handshake-angle'
      // if ( sport == 'climbing' ) return 'fa-solid fa-user'
      return 'fa-solid fa-circle-question'
    },

    get_icon_from_status(status) {
      if ( status == 'cancelled' ) return 'fa-solid fa-ban'
      if ( status == 'registrations_not_opened' ) return 'fa-solid fa-lock'
      if ( status == 'registrations_opened' ) return 'fa-solid fa-lock-open'
      if ( status == 'registrations_full' ) return 'fa-solid fa-face-frown'
      if ( status == 'registrations_closed' ) return 'fa-solid fa-lock'
      if ( status == 'waiting_for_start' ) return 'fa-solid fa-spinner fa-spin'
      if ( status == 'live' ) return 'fa-solid fa-circle fa-beat'
      if ( status == 'finished_provisional' ) return 'fa-solid fa-flag-checkered'
      if ( status == 'finished_official' ) return 'fa-solid fa-flag-checkered'
      return 'fa-solid fa-circle-question'
    },

    get_icon_color_from_status(status) {
      if ( status == 'live' ) return 'red'
      return ''
    },

    get_color_from_status(status) {
      if ( status == 'cancelled' ) return 'error'
      if ( status == 'registrations_not_opened' ) return 'info'
      if ( status == 'registrations_opened' ) return 'success'
      if ( status == 'registrations_full' ) return 'error'
      if ( status == 'registrations_closed' ) return 'error'
      if ( status == 'waiting_for_start' ) return ''
      if ( status == 'live' ) return 'error'
      if ( status == 'finished_provisional' ) return ''
      if ( status == 'finished_official' ) return 'success'
      return ''
    },

    get_icon_from_extra_service(es, unicode=false) {
      if ( es == 'water' ) return unicode ? 0xe4c5 : 'fa-solid fa-bottle-water'
      if ( es == 'food' ) return unicode ? 0xf2e7 : 'fa-solid fa-utensils'
      if ( es == 'shower' ) return unicode ? 0xf2cc : 'fa-solid fa-shower'
      if ( es == 'coffee' ) return unicode ? 0xf7b6 : 'fa-solid fa-mug-hot'
      if ( es == 'shelter' ) return unicode ? 0xe54f : 'fa-solid fa-person-shelter'
      if ( es == 'sleep' ) return unicode ? 0xf236 : 'fa-solid fa-bed'
      if ( es == 'doctor' ) return unicode ? 0xf0f9 : 'fa-solid fa-truck-medical'
      if ( es == 'assistance' ) return unicode ? 0xf4c4 : 'fa-solid fa-handshake-angle'
      if ( es == 'beer' ) return unicode ? 0xf0fc : 'fa-solid fa-beer-mug-empty'
      if ( es == 'vegan' ) return unicode ? 0xf06c : 'fa-solid fa-leaf'
      if ( es == 'vegetarian' ) return unicode ? 0xf7ef : 'fa-solid fa-cheese'
      if ( es == 'gluten_free' ) return unicode ? 0xe2cd : 'fa-solid fa-wheat-awn'
      if ( es == 'life_bag' ) return unicode ? 0xf290 : 'fa-solid fa-bag-shopping'
      if ( es == 'retirement' ) return unicode ? 0xf26 : 'fa-solid fa-hand'
      if ( es == 'transfer' ) return unicode ? 0xf5b6 : 'fa-solid fa-van-shuttle'
      return unicode ? 0xf059 : 'fa-solid fa-circle-question'
    },

    is_reader_active(datetime_start, datetime_end) {
      let now = this.moment().utc(true)
      if ( now >= this.moment.utc(datetime_start) && now <= this.moment.utc(datetime_end) ) {
        return true
      }
      return false
    },

    is_reader_alive(heartbeat, keep_alive=3) {
      let now = this.moment().utc(true).unix()
      heartbeat = this.moment.utc(heartbeat).unix()
      return now - heartbeat < keep_alive * 60
    },

    onScroll(e) {
      this.scroll_at_top = !(e.target.scrollingElement.scrollTop > 20);
    },

  },


  watch: {
    $route(to, from) {
      if (this.is_authenticated) {
        axios.post(`${process.env.VUE_APP_ROOT_API}/test?token=${this.token}`).then((response) => {
          if (response.data.success === false) {
            this.$store.commit('changeToken', {new_token: null})
            this.$store.commit('changeRole', {new_role: null})
            this.$store.commit('changeName', {new_name: null})
            router.push({name: 'LogIn'})
          }
        }).catch(() => {
          this.$store.commit('changeToken', {new_token: null})
          this.$store.commit('changeRole', {new_role: null})
          this.$store.commit('changeName', {new_name: null})
          router.push({name: 'LogIn'})
        })
      }
    }
  }
}
</script>

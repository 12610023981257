<template>
  <template v-if="loading">
    <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
  </template>
  <template v-else>

    <v-app-bar scroll-behavior="hide" scroll-threshold="100" density="compact" color="tertiary" v-if="event_data && is_admin">
      <v-app-bar-title>
        <v-chip label>{{ event_data.numeric_id }}</v-chip>
        <span class="hidden-xs">Admin</span>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn stacked :to="{name: 'AdminEvent', params: {event_id: event_data.slug}}">Editar</v-btn>
      <v-btn v-if="is_cm" stacked :disabled="Object.keys(modalities_winners_sorted).length == 0" @click="dialog_rrss = true">RRSS</v-btn>
    </v-app-bar>


    <v-sheet>
      <v-img class="w-100" cover lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
             :src="event_data.poster"
             height="150px" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)">
<!--        <v-row class="align-center justify-center"><v-col><v-btn variant="text" class="text-white">VER CARTEL</v-btn></v-col></v-row>-->
      </v-img>

      <v-sheet color="tertiary" v-if="event_data" class="text-caption">
        <v-row no-gutters class="ml-3 mr-2">
          <v-col cols="6">
            <span class="hidden-xs" v-if="event_data.date_start">{{ moment.utc(event_data.date_start.$date).format('LL') }}</span>
            <span class="hidden-sm-and-up" v-if="event_data.date_start">{{ moment.utc(event_data.date_start.$date).format('DD/MM/YYYY') }}</span>
          </v-col>
          <v-col class="text-right" cols="6" v-if="event_data.location && event_data.location.city">
            {{ event_data.location.city.split('/')[0].trim() }}
            <span class="hidden-xs" v-if="event_data.location.county">, {{
                event_data.location.county.split('/')[0].trim()
              }}</span>
          </v-col>
        </v-row>
      </v-sheet>


      <h4 v-if="event_data.external" class="bg-primary text-center text-uppercase">Evento externo</h4>
      <h1 class="text-center text-uppercase text-no-wrap text-truncate hidden-xs">{{ event_data.name_short || event_data.name }}</h1>
      <h2 class="text-center text-uppercase text-no-wrap text-truncate hidden-sm-and-up">{{ event_data.name_short || event_data.name }}</h2>
      <v-sheet class="text-center pb-1">
        <v-chip class="mr-1" size="small" variant="flat" label v-for="t in event_data.tags">{{ t }}</v-chip>
        <template v-for="o in event_data.organisms">
          <v-chip class="mr-1" pill size="small" v-if="o.type == 'federation'">
            <v-avatar start v-if="o.logo">
              <v-img :src="o.logo" cover></v-img>
            </v-avatar>
            {{ o.name }}
          </v-chip>
        </template>
      </v-sheet>

    </v-sheet>

    <v-sheet v-if="event_data.circuit" color="info" class="text-center">
      <span class="mr-1 hidden-xs">{{ $t('_event.circuit_part') }}</span>
      <router-link :to="{name: 'Circuit', params: {'circuit_id': event_data.circuit.slug}}">{{ event_data.circuit.name }}</router-link>
    </v-sheet>




    <v-container style="max-width: 960px;">

      <v-alert type="error" v-if="event_data.status == 'cancelled'" icon="fa-solid fa-warning fa-beat">
        <v-alert-title>Evento cancelado</v-alert-title>
        Revisa tu correo electrónico para más información
      </v-alert>


      <v-tabs
          v-if="this.live_statuses.includes(event_data.status) && !event_data.external"
          v-model="tab"
          align-tabs="center"
          center-active
      >
        <v-tab value="info">
          <v-icon size="small" icon="fa-solid fa-circle-info mr-2"></v-icon>
          {{ $t('_event.info') }}
        </v-tab>
        <v-tab value="results" v-if="this.live_statuses.includes(event_data.status) && !event_data.external">
          <span v-if="event_data.status=='live'">
            <v-icon start icon="fa-solid fa-circle fa-beat" color="red"></v-icon>
            {{ $t(`constants.event_status.${event_data.status}`) }}
          </span>
          <span v-else>
            <v-icon start icon="fa-solid fa-flag-checkered"></v-icon>
            Resultados
          </span>
        </v-tab>
      </v-tabs>

      <v-window v-model="tab" class="mt-4">
        <v-window-item value="info">
          <v-row>
            <v-col cols="12" sm="3" class="hidden-xs">
              <v-img class="w-100 mb-2" cover lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
                     :src="event_data.poster">
              </v-img>
              <AdSense :ad_slot="3437709174"></AdSense>
            </v-col>
            <v-col cols="12" sm="9" >
              <v-row v-if="event_data.external && ['finished_provisional', 'finished_official'].includes(event_data.status)">
                <v-col>
                  <v-btn variant="elevated" block color="primary">
                    Resultados externos
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!this.live_statuses.includes(event_data.status)">
                <v-col>
                  <v-btn :to="{name: 'EventRegister', params: {'event_id': this.event_id}}"
                         variant="elevated" :color="event_data.status == 'registrations_not_opened' ? '' : event_data.status == 'registrations_opened' ? 'primary' : 'error' " block
                         :disabled="event_data.status != 'registrations_opened'">
                    <span v-if="event_data.status == 'registrations_opened'">{{ $t('_event.register') }}</span>
                    <span v-else>{{ $t(`constants.event_status.${event_data.status}`) }}</span>
                  </v-btn>
                  <h5 class="text-caption text-center">
                    <span v-if="event_data.registration_start && event_data.registration_end">
                      {{ moment.utc(event_data.registration_start.$date).format("DD/MM HH:mm") }}
                      - {{ moment.utc(event_data.registration_end.$date).format("DD/MM HH:mm") }}
                      <v-icon v-if="event_data.modalities.length > 1"  icon="fa-solid fa-circle-info"  size="x-small"></v-icon>
                    </span>
                    <v-tooltip v-if="event_data.modalities.length > 1" location="bottom" activator="parent">
                      Consulte las fechas de inscripciones según modalidad
                    </v-tooltip>
                  </h5>
                  <v-tooltip activator="parent" location="bottom left"
                             v-if="this.live_statuses.includes(event_data.status)">
                    {{ $t('_event.error_registration') }}
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn :to="{name: 'EventRegistrations', params: {'event_id': this.event_id}}" variant="outlined"
                         v-if="event_data.show_registration_list" :disabled="event_data.status == 'registrations_not_opened'"
                         block class="mb-1">{{ $t('_event.registration_list') }}</v-btn>
                  <v-btn :href="`https://inscripciones.mychip.es/es/evento/${event_data.slug_rts}/my-registration`" variant="outlined"
                         :disabled="event_data.status == 'registrations_not_opened'"
                         block class="mb-1">
                    <span v-if="event_data.status == 'registrations_opened'">{{ $t('_event.edit_registration') }}</span>
                    <span v-else>Ver mi inscripción</span>
                  </v-btn>
                  <v-btn v-if="event_data.allow_team_registration && event_data.status == 'registrations_opened'" :href="`https://inscripciones.mychip.es/es/evento/${event_data.slug_rts}/edicion-equipos`" variant="outlined"
                         block class="mb-1">{{ event_data.team_registration_name }}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-caption text-capitalize">{{ $t('_event.date') }}</span>
                  <h3 class="hidden-xs">
                    {{ moment.utc(event_data.date_start.$date).format("LL") }}
                    <template v-if="event_data.date_end && moment.utc(event_data.date_start.$date).format('LL') != moment.utc(event_data.date_end.$date).format('LL')">
                      - {{ moment.utc(event_data.date_end.$date).format("LL") }}
                    </template>
                  </h3>
                  <h3 class="hidden-sm-and-up">
                    {{ moment.utc(event_data.date_start.$date).format("DD/MM/YYYY") }}
                    <template v-if="event_data.date_end && moment.utc(event_data.date_start.$date).format('LL') != moment.utc(event_data.date_end.$date).format('LL')">
                      - {{ moment.utc(event_data.date_end.$date).format("DD/MM/YYYY") }}
                    </template>
                  </h3>
                </v-col>
                <v-col class="text-right">
                  <span class="text-caption text-capitalize">{{ $t('_event.place') }}</span>
                  <h3 v-if="event_data.location">
                    <span v-if="event_data.location.city">{{ event_data.location.city.split('/')[0] }}</span>
                    <span v-if="event_data.location.county"><span v-if="event_data.location.city">, </span>{{ event_data.location.county.split('/')[0] }}</span>
                    <v-btn
                        v-if="event_data.location && modalities_same_location"
                        target="_blank"
                        :href="`https://www.google.com/maps/search/?api=1&query=${event_data.location.coordinates.coordinates[0]},${event_data.location.coordinates.coordinates[1]}`"
                        icon variant="text" size="x-small">
                      <v-icon size="small" icon="fa-solid fa-map"></v-icon>
                    </v-btn>
                    <template v-if="!modalities_same_location">
                      <span>*</span>
                      <v-icon size="x-small" end icon="fa-solid fa-circle-info mr-2"></v-icon>
                      <v-tooltip activator="parent" location="bottom">
                        Cada modalidad tiene un lugar distinto de salida.
                        <br>
                        Compruébelo en el cuadro de información de cada modalidad.
                      </v-tooltip>
                    </template>
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="event_data.organisms && event_data.organisms.filter(function (o) { return o.type == 'organizer' }).length > 0">
                  <span class="text-caption">{{ $t('_event.organization') }}</span>
                    <v-chip-group column>
                    <template v-for="o in event_data.organisms.filter(function (o) { return o.type == 'organizer' })">
                      <v-chip class="mr-1" pill size="small">
                        <v-avatar start v-if="o.logo">
                          <v-img :src="o.logo" cover></v-img>
                        </v-avatar>
                        {{ o.name }}
                      </v-chip>
                    </template>
                    </v-chip-group>
                </v-col>
                <v-col cols="6" class="text-right" v-if="event_data.contact">
                  <span class="text-caption">{{ $t('_event.contact') }}</span>
                  <br>
                  <v-chip-group column class="text-right">
                  <v-chip v-if="event_data.contact.email" :href="`mailto:${event_data.contact.email}`" label size="small" class="mr-1">
                    <v-icon start size="x-small" icon="fa-solid fa-envelope"/>
                    {{event_data.contact.email}}
                  </v-chip>
                  <v-chip v-if="event_data.contact.phone" :href="`tel:${event_data.contact.phone}`" label size="small" class="mr-1">
                    <v-icon start size="x-small" icon="fa-solid fa-phone"/>
                    {{event_data.contact.phone}}
                  </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row v-if="event_data.documents.filter((x) => x.type == 'registration').length > 0" >
                <v-col>
                  <span class="text-caption">{{ $t('_event.documents') }}</span>
                  <br>

                  <v-chip-group column>
                    <template v-for="d in event_data.documents">
                      <v-chip label v-if="d.type == 'registration'" :href="d.url" target="_blank">
                        <v-icon start size="small" icon="fa-solid fa-file"></v-icon>
                        {{d.name}}
                      </v-chip>
                    </template>
                  </v-chip-group>
                </v-col>
              </v-row>

              <v-row v-if="ads && ads.length > 0">
                <v-col>
                  <h4>Patrocinadores y ofertas del evento</h4>
                  <v-carousel v-if="ads" height="150" :show-arrows="false" :cycle="true" :interval="3000" vertical-delimiters direction="vertical">
                    <v-carousel-item v-for="(ad, i) in ads" :key="i">
                      <v-sheet class="w-100 h-100 pa-2" color="white">
                        <v-row v-if="ad.redirect" no-gutters>
                          <v-col offset="2">
                            <v-btn size="x-small" color="primary" class="text-uppercase" block :href="ad.redirect" target="_blank">Ver {{ad.name}}</v-btn>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 pt-0">
                          <v-col offset="2">
                            <v-img class="h-50 mt-2" :src="ad.image" ></v-img>
                          </v-col>
                          <v-col cols="6" v-if="ad.extra_text && ad.extra_text.length > 0">
                            <span class="text-caption mt-2">{{ ad.extra_text }}</span>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <span class="text-caption">{{event_data.modalities.length }} {{ $tc('_event.modality', event_data.modalities.length) }}</span>

                  <template v-if="loading_modalities">
                    <v-progress-linear indeterminate :height="12" v-if="loading_modalities"></v-progress-linear>
                  </template>

                  <v-card class="mb-2" v-for="(m, idx_modality) in modalities">

                    <v-toolbar v-if="m.circuit_modality" color="info" density="compact" height="1">
                      <span class="pl-2">Puntúa en el circuito</span>
                    </v-toolbar>

                    <v-card-subtitle class="mt-2">
                      <v-row>
                        <v-col>
                          <v-icon start size="x-small" icon="fa-solid fa-clock"></v-icon>
                          <span class="hidden-xs">
                            {{ moment.utc(m.datetime_start.$date).format('LL') }} {{ $t('_event.datetime_at') }}
                            {{ moment.utc(m.datetime_start.$date).format('HH:mm') }}
                          </span>
                          <span class="hidden-sm-and-up">
                            {{moment.utc(m.datetime_start.$date).format('DD/MM HH:mm')}}
                          </span>
                        </v-col>
                        <v-col class="text-right">
                          <v-chip label size="x-small">
                            <v-icon start :icon="$root.get_icon_from_status(m.status)" size="x-small"></v-icon>
                            {{$t(`constants.event_status.${m.status}`)}}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>

                    <v-card-title class="pt-0">
                      <v-row>
                        <v-col cols="9">
                          {{ m.short_name || m.name }}
                        </v-col>
                        <v-col class="text-right" cols="3" v-if="m.price">
                          <v-chip label size="small" color="primary">
                            {{ m.price }}€<span v-if="m.price_history.length > 1">*</span>
                            <v-tooltip activator="parent" location="bottom right" class="text-right" v-if="m.price_history.length > 1">
                              Los precios varían según la fecha.
                              <br>
                              Consulta toda la información del evento
                            </v-tooltip>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-title>


                    <v-card-text>
                      <v-chip-group column>


                        <v-dialog fullscreen scrim
                                  v-if="m.gpx_track"
                                  transition="dialog-bottom-transition" scrollable
                                  width="100%">

                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" label size="small" class="bg-success">
                              <v-icon  size="x-small" icon="fa-solid fa-route" start></v-icon>
                              Ver recorrido
                            </v-chip>
                          </template>

                          <template v-slot:default="{ isActive }">

                          <v-toolbar color="primary">

                            <v-toolbar-title>
                              Track y perfil
                              <h3>{{ m.short_name || m.name }}</h3>
                            </v-toolbar-title>
                            <v-toolbar-items>
                              <v-btn @click="download_track(m)">
                                <v-icon icon="fa-solid fa-download" disabled=""></v-icon>
                              </v-btn>
                              <v-btn @click="isActive.value = false" icon>
                                <v-icon icon="fa-solid fa-times"></v-icon>
                              </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>
                          <MychipMap style="height: 100%; width: 100%" :modality="m"></MychipMap>
                          </template>

                        </v-dialog>

                        <v-chip label size="small" class="mr-1">
                          <v-icon start :icon="this.$root.get_icon_from_sport(m.sport)" size="x-small"></v-icon>
                          {{ $t(`constants.sport.${m.sport}`) }}
                        </v-chip>
                        <v-chip label size="small" class="mr-1" v-if="m.distance">{{ $root.parse_distance(m.distance, 1, 1) }}</v-chip>
                        <v-chip label size="small" class="mr-1" v-if="m.slope">{{ $root.parse_elevation(m.slope) }}</v-chip>
                      </v-chip-group>
                    </v-card-text>

                    <v-expand-transition>
                      <div v-show="m['info_panel']" class="mt-2">
                        <v-divider></v-divider>

                        <v-card-text class="text-caption font-italic" v-if="m.description">{{ m.description }}</v-card-text>

                        <v-card-text>


                          <v-expansion-panels variant="popout">

                            <v-expansion-panel>
                              <v-expansion-panel-title>Timeline</v-expansion-panel-title>
                              <v-expansion-panel-text>
                                <v-table density="compact">
                                  <tbody>
                                  <tr v-if="m.registration_start">
                                    <td>
                                      {{ moment.utc(m.registration_start.$date).format("DD/MM HH:mm") }} -
                                      {{ moment.utc(m.registration_end.$date).format("DD/MM HH:mm") }}
                                    </td>
                                    <td>Inscripciones</td>
                                  </tr>
                                  <tr>
                                    <td>{{ moment.utc(m.datetime_start.$date).format("DD/MM HH:mm") }}</td>
                                    <td>
                                      Salida
                                      <v-btn size="x-small" :href="`https://www.google.com/maps/search/?api=1&query=${m.location_start.coordinates.coordinates[0]},${m.location_start.coordinates.coordinates[1]}`"
                                      target="_blank">Ver mapa</v-btn>
                                    </td>
                                  </tr>
                                  <tr v-if="m.datetime_end">
                                    <td>{{ moment.utc(m.datetime_end.$date).format("DD/MM HH:mm") }}</td>
                                    <td>Cierre de meta</td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel>
                              <v-expansion-panel-title>Recorrido (avituallamientos y puntos de control)</v-expansion-panel-title>
                              <v-expansion-panel-text>
                                <v-table density="compact">
                                  <tbody>
                                  <tr>
                                    <td>{{ $root.parse_distance(0, 0, 1) }}</td>
                                    <td>Salida</td>
                                    <td>{{ moment.utc(m.datetime_start.$date).format("DD/MM HH:mm") }}</td>
                                    <td>
                                    <span class="mr-1" v-if="m.is_timed">
                                      <v-icon class="mr-1" size="small" icon="fa-solid fa-stopwatch"></v-icon>
                                      <v-tooltip activator="parent" location="bottom">Cronometrado</v-tooltip>
                                    </span>
                                    </td>
                                  </tr>
                                  <tr v-for="int in m.intermediates">
                                    <td>{{$root.parse_distance(int.distance_from_start, 1, 1)}}</td>
                                    <td>{{ int.location.custom_name }}</td>
                                    <td></td>
                                    <td>
                                      <span class="mr-1"><v-icon class="mr-1" size="small" icon="fa-solid fa-stopwatch" v-if="int.timed"></v-icon><v-tooltip activator="parent" location="bottom">Cronometrado</v-tooltip></span>
                                      <template v-for="es in int.extra_services">
                                      <span class="mr-1">
                                        <v-icon class="mr-1" size="small" :icon="$root.get_icon_from_extra_service(es)"></v-icon>
                                        <v-tooltip activator="parent" location="bottom">
                                          {{es}}
                                        </v-tooltip>
                                      </span>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{{ $root.parse_distance(m.distance, 1, 1) }}</td>
                                    <td>Meta</td>
                                    <td>
                                    <span v-if="m.datetime_end">
                                      cierre {{ moment.utc(m.datetime_end.$date).format("DD/MM HH:mm") }}
                                    </span>
                                    </td>
                                    <td>
                                    <span class="mr-1" v-if="m.is_timed">
                                      <v-icon class="mr-1" size="small" icon="fa-solid fa-stopwatch"></v-icon>
                                      <v-tooltip activator="parent" location="bottom">Cronometrado</v-tooltip>
                                    </span>
                                      <template v-for="es in m.extra_services">
                                      <span class="mr-1">
                                        <v-icon class="mr-1" size="small" :icon="$root.get_icon_from_extra_service(es)"></v-icon>
                                        <v-tooltip activator="parent" location="bottom">{{es}}</v-tooltip>
                                      </span>
                                      </template>
                                    </td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="m.price_history.length > 1">
                              <v-expansion-panel-title>Precios</v-expansion-panel-title>
                              <v-expansion-panel-text>
                                <v-table density="compact">
                                  <tbody>
                                  <tr v-for="p in m.price_history">
                                    <td class="">{{ moment.utc(p.start.$date).format("DD/MM HH:mm") }} -
                                      {{ moment.utc(p.end.$date).format("DD/MM HH:mm") }}
                                    </td>
                                    <td>{{ p.price }}€</td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="m.categories.length > 0">
                              <v-expansion-panel-title>Categorías</v-expansion-panel-title>
                              <v-expansion-panel-text>
                                <v-table density="compact">
                                  <tbody>
                                  <tr v-for="c in m.categories">
                                    <td class="text-uppercase">{{ c.name }}</td>
                                    <td>{{ c.gender }}</td>
                                    <td>de {{ c.age_from }} a {{ c.age_to }} años</td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="m.prizes.length > 0">
                              <v-expansion-panel-title>Trofeos</v-expansion-panel-title>
                              <v-expansion-panel-text>
                                <v-alert color="info" icon="fa-solid fa-info" density="compact">
                                  Esta información está basada en la interpretación del reglamento de la prueba, por lo que es posible que muestre información errónea.
                                  Consulte el reglamento oficial para más información.
                                  La organización es la única responsable de decidir que trofeos se otorgan el día de la carrera.
                                </v-alert>
                                <v-table density="compact">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Trofeos</th>
                                      <th>Por</th>
                                      <th>Acumulativos</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="prize, idx in m.prizes">
                                    <td class="font-weight-light font-italic text-caption">
                                      <v-chip size="x-small" variant="outlined">{{ idx + 1 }}</v-chip>
                                    </td>
                                    <td>{{ prize.total }}</td>
                                    <td>
                                      <span v-if="prize.by == 'general'">clasificación general</span>
                                      <span v-if="prize.by == 'gender'">clasificación género ({{ $t(`constants.gender.male`) }} y {{ $t(`constants.gender.female`) }})</span>
                                      <span v-if="prize.by == 'category'">categoría</span>
                                      <span v-if="prize.by == 'local'">locales (general)</span>
                                      <span v-if="prize.by == 'local_gender'">locales género ({{ $t(`constants.gender.male`) }} y {{ $t(`constants.gender.female`) }})</span>
                                      <span v-if="prize.by == 'local_category'">locales por categoría</span>
                                    </td>
                                    <td>
                                      <span v-if="prize.non_cummulative_with.length > 0">
                                        No acumula con 
                                        <v-chip size="x-small" variant="outlined" v-for="nc, idx in prize.non_cummulative_with">{{ idx + 1 }}</v-chip>
                                      </span>
                                    </td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </v-expansion-panel-text>
                            </v-expansion-panel>

                          </v-expansion-panels>

                        </v-card-text>
                      </div>
                    </v-expand-transition>

                    <v-btn block size="small" variant="tonal" class="mt-2"
                           @click="m['info_panel'] = !m['info_panel']">
                      <span v-if="!m['info_panel']">Más</span><span v-else>Menos</span><span
                        class="ml-1">información</span>
                    </v-btn>


                  </v-card>


                </v-col>
              </v-row>

              <v-row no-gutters class="hidden-sm-and-up">
                <v-col class="mt-2">
                  <AdSense :ad_slot="1348879686"></AdSense>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item value="results" v-if="this.live_statuses.includes(event_data.status) && !event_data.external">

          <v-row align-content="center" justify="center" class="text-center">
            <v-col cols="12" sm="4">
              <v-btn block variant="elevated" color="primary" @click="dialog_search = true ">
                <v-icon size="x-small" start icon="fa-solid fa-magnifying-glass"></v-icon>
                <span>Buscar participante</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="false">
            <v-col cols="12">
              <v-card :loading="!modalities_stats_combined" >
                <v-card-title>Estadisticas generales</v-card-title>
                <v-card-text v-if="modalities_stats_combined">

                  <v-hover v-slot="{ isHovering, props }">
                    <v-chip v-bind="props" label size="small" class="mr-1">{{ modalities_stats_combined.total }} inscritos</v-chip>
                    <v-expand-transition>
                      <template v-if="isHovering">
                        <span>
                          <v-chip class="mr-1" v-for="(t, g) in modalities_stats_combined.gender" label size="small" variant="outlined">{{ t }} {{ g }}</v-chip>
                        </span>
                      </template>
                    </v-expand-transition>
                  </v-hover>

                  <v-hover v-slot="{ isHovering, props }">
                    <v-chip v-bind="props" label size="small" class="mr-1">{{ Object.keys(modalities_stats_combined.club).length }} clubes</v-chip>
                    <v-expand-transition>
                      <template v-if="isHovering">
                        <span>
                          <v-chip class="mr-1" v-for="(v, idx) in Object.fromEntries(Object.entries(modalities_stats_combined.club).slice(1, 7))" label size="small" variant="outlined">{{ idx }}: {{ v }}</v-chip>
                        </span>
                      </template>
                    </v-expand-transition>
                  </v-hover>

                  <v-hover v-slot="{ isHovering, props }">
                    <v-chip v-bind="props" label size="small" class="mr-1">{{ Object.keys(modalities_stats_combined.team).length }} equipos</v-chip>
                    <v-expand-transition>
                      <template v-if="isHovering">
                        <span>
                          <v-chip class="mr-1" v-for="(v, idx) in Object.fromEntries(Object.entries(modalities_stats_combined.team).slice(1, 7))" label size="small" variant="outlined">{{ idx }}: {{ v }}</v-chip>
                        </span>
                      </template>
                    </v-expand-transition>
                  </v-hover>

                </v-card-text>

              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center">
            <template v-for="m in event_data.modalities">
              <v-col cols="12" sm="6" v-if="m.is_timed">
                <v-card>
                  <v-card-actions>
                    <v-btn :disabled="!(['live', 'finished_provisional', 'finished_official'].includes(m.status))" block
                           variant="elevated" color="primary"
                           :to="{ name: 'Modality', params: { modality_id: m._id.$oid }}">
                      <v-icon start :icon="$root.get_icon_from_status(m.status)" :color="$root.get_icon_color_from_status(m.status)" size="x-small" ></v-icon>
                      <span v-if="m.status=='live'">SEGUIMIENTO</span>
                      <span v-else-if="m.status=='finished_provisional'">RESULTADOS PROVISIONALES</span>
                      <span v-else-if="m.status=='finished_official'">RESULTADOS OFICIALES</span>
                      <span v-else>ESPERANDO...</span>
                    </v-btn>
                  </v-card-actions>
  <!--                <v-card-title v-if="m.bib_color" style="`background-color: ${m.bib_color}`"></v-card-title>-->
  <!--                <v-card-subtitle class="bg-primary text-caption">a</v-card-subtitle>-->
                  <v-card-title>
                    {{ m.short_name || m.name }}
                    <v-chip v-if="m.distance" label size="small" class="ml-1">{{ parseInt(m.distance / 1000) }}K</v-chip>
                    <v-chip v-if="m.slope" label size="small" class="ml-1">{{ parseInt(m.slope) }}m+</v-chip>
                  </v-card-title>
                  <v-card-subtitle class="mb-1">

                    <v-chip-group column>

                    <v-chip label size="small" variant="elevated" class="mr-1">
                      <v-icon start :icon="$root.get_icon_from_status(m.status)" :color="m.status == 'live' ? 'red' : ''" size="x-small"></v-icon>
                      <span class="text-uppercase">{{ $t(`constants.event_status.${m.status}`) }}</span>
                    </v-chip>

                    <v-chip class="mr-1" label size="small" variant="elevated" v-if="m.status == 'live' && m.chrono_start && now">
                      {{ this.$root.parse_time_from_seconds((now.diff(moment(m.chrono_start.$date)))/1000) }}
                      <v-tooltip activator="parent" location="bottom">
                        Empezó {{ moment.utc(m.chrono_start.$date).format('DD/MM HH:mm') }}
                      </v-tooltip>
                    </v-chip>

                    <v-chip label size="small" class="mr-1">
                      <v-icon start :icon="this.$root.get_icon_from_sport(m.sport)" size="x-small"></v-icon>
                      {{ $t(`constants.sport.${m.sport}`) }}
                    </v-chip>

                      {{ void(m_stats = modalities_stats[m._id.$oid])}}

                    <v-chip label size="small" class="mr-1" v-if="m_stats && m_stats.total">
                      {{ m_stats.total }} registrados
                    </v-chip>
                    <v-chip label size="small" class="mr-1" v-if="m_stats && m_stats.status && m_stats.status.finished">
                      {{ m_stats.status.finished }} en meta
                    </v-chip>
                    <v-chip label size="small" class="mr-1" v-if="m_stats && m_stats.status && m_stats.status.took_start">
                      {{ m_stats.status.took_start }} empezados
                    </v-chip>
                    <v-chip label size="small" class="mr-1" v-if="m_stats && m_stats.status && m_stats.status.retired">
                      {{ m_stats.status.retired }} retirados
                    </v-chip>

                    </v-chip-group>

                  </v-card-subtitle>

                  <v-card-text class="mt-0 pt-0">
                    <template v-if="['live', 'finished_provisional', 'finished_official'].includes(m.status)">
                      <template v-if="Object.keys(modalities_winners).length > 0 && m._id.$oid in modalities_winners && modalities_winners[m._id.$oid].length > 0">
                        <span class="text-caption text-uppercase">Podium</span>
                        <v-divider></v-divider>
                        <template v-for="p in 3">
                          <v-row no-gutters class="align-center" v-if="modalities_winners[m._id.$oid].find(i => i.result.shown_order_gender == p)">
                            <v-col class="text-center justify-center align-center"><v-chip size="x-small" rounded :color="(p==1)?'gold':(p==2)?'silver':'bronze'" variant="flat">{{p}}</v-chip></v-col>
                            <v-col cols="10" class="text-no-wrap text-truncate">
                              <template v-for="g in ['male', 'female']">
                                {{ void(w = modalities_winners[m._id.$oid].find(i => i.gender == g && i.result.shown_order_gender == p)) }}
                                <template v-if="w">
                                  <span>{{ w.name }} <b>{{ w.surname }}</b></span>
                                  <span class="text-caption font-weight-light text-capitalize" v-if="w.club"> | {{w.club}}</span>
                                  <br v-if="g == 'male' && w">
                                </template>
                              </template>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                        </template>
                      </template>
                    </template>
                  </v-card-text>
                  
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-window-item>
      </v-window>

    </v-container>

    <v-dialog v-model="dialog_search" fullscreen scrim
              transition="dialog-bottom-transition" scrollable
              width="auto">
      <v-card>
        <v-toolbar
            color="primary"
            title="Búsqueda participantes"
        >
          <v-toolbar-items>
            <v-btn @click="dialog_search = false" icon>
              <v-icon icon="fa-solid fa-times"></v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>

          <v-row>
            <v-col>
              <v-text-field v-model="free_text_search" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                            @click:append-inner="search_registration" @click:clear="search_registration" @keydown.enter="search_registration"
                            hint="Busque por dorsal, nombre, apellidos o club" persistent-hint ref="dialog_search_field" autofocus
              ></v-text-field>
            </v-col>
          </v-row>

          <v-progress-linear indeterminate v-if="loading_search_result"></v-progress-linear>

          <v-table>
            <tbody>
            <tr v-for="r in search_result">
              <td>
                <router-link :to="{name: 'Modality', params: {modality_id: r.modality._id.$oid}}" style="color: #1a222d">
                  <span class="text-caption text-capitalize ">{{r.modality.short_name || (r.modality.name).substring(0, 8)}}</span>
                </router-link>
              </td>
              <td>
                <span class="text-caption">{{r.bib_number}}</span>
              </td>
              <td class="text-caption">
                <router-link :to="{name: 'IndividualResult', params: {result_id: r._id.$oid}}" style="color: #1a222d">
                  <span>{{ r.name }} <b class="text-uppercase text-no-wrap">{{ r.surname }}</b></span>
                  <br>
                  <span class="text-caption">{{r.club}}</span>
                </router-link>
              </td>
              <td>
                <span class="text-caption" v-if="r.result && r.result.status">
                  <v-chip label size="x-small" class="text-uppercase">
                    <v-icon v-if="r.result.status == 'finished'" start size="x-small" icon="fa-solid fa-flag-checkered"></v-icon>
                    {{ $t(`result_status.${r.result.status}`) }}
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip label size="x-small" class="text-uppercase">
                    Esperando
                  </v-chip>
                </span>
              </td>
            </tr>
            </tbody>
          </v-table>

        </v-card-text>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_rrss" fullscreen
              transition="dialog-bottom-transition" scrollable
              width="auto">
      <v-card>
        <v-toolbar
            color="primary"
            title="Generar contenido RRSS">
          <v-toolbar-items>
            <v-btn @click="dialog_rrss = false" icon>
              <v-icon icon="fa-solid fa-times"></v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text >
          <template v-if="event_data && modalities_winners_sorted">
              <v-expansion-panels>
                <template v-for="(winners, modality_id) in modalities_winners_sorted">
                  <v-expansion-panel v-if="modalities[modality_id].is_timed" @group:selected="rrss_selection = JSON.parse(JSON.stringify(rrss_selection_original))">
                    <v-expansion-panel-title>{{ modalities[modality_id].short_name || modalities[modality_id].name }}</v-expansion-panel-title>
                    <v-expansion-panel-text v-if="rrss_selection">
                      <v-checkbox hide-details v-model="rrss_selection.event_name" :label="event_data.name"></v-checkbox>
                      <v-checkbox v-if="event_data.rrss_hashtags.length > 0" hide-details v-model="rrss_selection.event_hashtag" :label="event_data.rrss_hashtags.join(' ')"></v-checkbox>
                      <v-checkbox hide-details v-model="rrss_selection.modality_name" :label="modalities[modality_id].short_name || modalities[modality_id].name"></v-checkbox>
                      <v-checkbox v-if="modalities[modality_id].rrss_hashtags.length > 0" hide-details v-model="rrss_selection.modality_hashtag" :label="modalities[modality_id].rrss_hashtags.join(' ')"></v-checkbox>
                      <template v-for="(winners_p, p) in winners">
                        <template v-for="(winners_g, g) in winners_p">
                          <v-checkbox v-model="rrss_selection[g][p]" class="text-no-wrap overflow-hidden" hide-details :label="`${p} (${g}) - ${winners_g.name} ${winners_g.surname}`"></v-checkbox>
                        </template>
                      </template>
                      <v-checkbox v-model="rrss_selection.include_time" hide-details label="Incluir tiempo"></v-checkbox>
                      <v-checkbox v-model="rrss_selection.include_club" hide-details label="Incluir club"></v-checkbox>
                      <v-btn block @click="generate_winners_clipboard(modality_id)" color="primary">
                        <v-icon start size="small" icon="fa-solid fa-copy"></v-icon>
                        Copiar texto
                        <v-icon v-if="rrss_copied" end size="small" color="success" icon="fa-solid fa-circle-check"></v-icon>
                      </v-btn>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
          </template>

        </v-card-text>
      </v-card>
    </v-dialog>

  </template>
</template>

<script>
import {defineComponent} from 'vue';
// import "leaflet/dist/leaflet.css";
// import L from 'leaflet';
// import {LMap, LTileLayer} from "@vue-leaflet/vue-leaflet";
import MychipMap from "@/components/MychipMap.vue";
import Winner from "@/components/Winner.vue";
import AdSense from '../components/AdSense.vue'


export default defineComponent({
  name: 'EventView',
  components: {
    MychipMap,
    Winner,
    AdSense,
  },
  data: () => ({
    now: null,
    tab: "info",
    loading: true,
    loading_modalities: true,
    event_id: null,
    event_data: null,
    modalities: {},
    modalities_stats: {},
    modalities_stats_combined: null,
    modalities_winners: {},
    modalities_winners_sorted: {},
    dialog_track: true,
    zoom: 5,
    model: null,
    ads: null,
    free_text_search: null,
    dialog_rrss: false,
    dialog_search: false,
    search_result: null,
    loading_search_result: false,
    live_statuses: ['waiting_for_start', 'live', 'finished_provisional', 'finished_official'],
    modalities_same_location: true,
    rrss_selection_original: {
      event_name: true,
      event_hashtag: true,
      modality_name: true,
      modality_hashtag: true,
      include_time: false,
      include_club: true,
      male: {
        "1": false,
        "2": false,
        "3": false,
      },
      female: {
        "1": false,
        "2": false,
        "3": false,
      },
      other: {
        "1": false,
        "2": false,
        "3": false,
      },
    },
    rrss_selection: null,
    rrss_copied: false,
  }),
  created() {
    this.event_id = this.$route.params.event_id
    this.now = this.moment().utc(true)

    this.fetch_event_data()

    setInterval(() => {
      this.now = this.moment().utc(true)
    }, 1000)


  },
  methods: {
    fetch_event_data() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {'slug': this.event_id}
      ).then((response) => {
        this.event_data = response.data[0]

        if (
            this.live_statuses.includes(this.event_data.status)
            && !this.event_data.external
        ) {
          this.tab = "results"
        }

        this.modalities_same_location = this.event_data.modalities.map(m => m.location_start)
            .every( (val, i, arr) => JSON.stringify(val) === JSON.stringify(arr[0]) )

        this.loading = false
        document.title = this.event_data.name_short || this.event_data.name
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.fetch_event_stats()
        this.fetch_modalities()
        this.fetch_ads()
        this.fetch_modalities_stats()
        this.fetch_modalities_winners()
      })
    },
    fetch_event_stats() {
      this.axios.post(`${process.env.VUE_APP_ROOT_API}/registrations/stats`, {
        "event": this.event_data.slug,
      }).then((response) => {
        this.modalities_stats_combined = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_modalities() {
      this.loading_modalities = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/modalities`,
          {event: this.event_data._id.$oid}
      ).then((response) => {
        let modalities = response.data
        modalities.forEach(m => {
          this.modalities[m._id.$oid] = m
          this.modalities[m._id.$oid]["info_panel"] = false
        })
        this.loading_modalities = false
      }).catch((error) => {
        console.error(error)
      })
      // this.event_data.modalities.forEach(modality => {
      //   this.axios.post(
      //       `${process.env.VUE_APP_ROOT_API}/modalities`,
      //       {
      //         'id': modality._id.$oid,
      //         'include_event': false
      //       }
      //   ).then((response) => {
      //     this.modalities[modality._id.$oid] = response.data
      //     this.modalities[modality._id.$oid]["info_panel"] = false
      //     if (Object.values(this.modalities).length == this.event_data.modalities.length) {
      //       this.loading_modalities = false
      //     }
      //   }).catch((error) => {
      //     console.error(error)
      //   })
      // })
    },
    fetch_modalities_stats() {
      this.event_data.modalities.forEach((modality, idx) => {
        this.axios.post(`${process.env.VUE_APP_ROOT_API}/registrations/stats`, {
          "modality": modality._id.$oid,
          "simple_stats": true,
        }).then((response) => {
          this.modalities_stats[modality._id.$oid] = response.data
        }).catch((error) => {
          console.error(error)
        })
      })
    },
    fetch_modalities_winners() {
      this.event_data.modalities.forEach(modality => {
        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/registrations`,
            {
              "modality": modality._id.$oid,
              "winners": true,
            }
        ).then((response) => {
          this.modalities_winners[modality._id.$oid] = response.data
          this.modalities_winners_sorted[modality._id.$oid] = null

          this.modalities_winners[modality._id.$oid].forEach( winner => {
            if ( this.modalities_winners_sorted[modality._id.$oid] == null ) {
              this.modalities_winners_sorted[modality._id.$oid] = {}
            }
            if ( !(winner.result.pos_gender in this.modalities_winners_sorted[modality._id.$oid]) ) {
              this.modalities_winners_sorted[modality._id.$oid][winner.result.pos_gender] = {}
            }
            this.modalities_winners_sorted[modality._id.$oid][winner.result.pos_gender][winner.gender] = winner
          })
        }).catch((error) => {
          console.error(error)
        })
      })
    },
    fetch_ads() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/ads`,
          {
            "event_id": this.event_data._id.$oid
          }
      ).then((response) => {
        this.ads = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    search_registration() {
      this.search_result = null
      if ( this.free_text_search !== null ) {
        this.loading_search_result = true
        let url = `${process.env.VUE_APP_ROOT_API}/registrations`
        this.axios.post(
            url,
            {
              "event": this.event_id,
              "free_text_search": this.free_text_search,
              "limit": 20,
              "order": ["bib_number"]
            }
        ).then((response) => {
          this.search_result = response.data
          this.loading_search_result = false
        }).catch((error) => {
          console.error(error)
        })
      }
    },

    generate_winners_clipboard(mod) {
      let t = ""
      console.log(this.modalities_winners_sorted)
      if ( this.rrss_selection.event_name ) {
        t = `${this.event_data.name_short || this.event_data.name} `
      }
      if ( this.rrss_selection.event_hashtag ) {
        t = `${t}${this.event_data.rrss_hashtags.join(' ')}`
      }
      if ( t.length > 0 ) {
        t = `${t}\n`
      }
      if ( this.rrss_selection.modality_name ) {
        t = `${t}${this.modalities[mod].short_name || this.modalities[mod].name} `
      }
      if ( this.rrss_selection.modality_hashtag ) {
        t = `${t}${this.modalities[mod].rrss_hashtags.join(' ')}`
      }
      for (let p in this.modalities_winners_sorted[mod] ) {
        let winners_p = this.modalities_winners_sorted[mod][p]
        for ( let g in this.modalities_winners_sorted[mod][p] ) {
          let winner_g = this.modalities_winners_sorted[mod][p][g]
          if ( this.rrss_selection[g][p] ) {
            let t_winner = `${p}. ${(winner_g.name.toUpperCase())} ${(winner_g.surname.toUpperCase())}`
            if ( this.rrss_selection.include_club && winner_g.club && winner_g.club.length > 0 ) {
              t_winner = `${t_winner} (${winner_g.club.toUpperCase()})`
            }
            if ( this.rrss_selection.include_time ) {
              t_winner = `${t_winner} ${this.$root.parse_time_from_seconds(winner_g.result.total_time)}`
            }
            t = `${t.length > 0 ? `${t}\n` : ''}${t_winner}`
          }
        }
      }
      navigator.clipboard.writeText(t)
      navigator.clipboard
          .writeText(t)
          .then(() => {
            this.rrss_copied = true
            setTimeout(() => {
              this.rrss_copied = false
            }, 1000)
          })
          .catch(() => {
            alert("something went wrong");
          });
    },
    download_track(m) {
      let file_name = "track.gpx"
      file_name = `${parseInt(m.distance/1000)}K__${m.short_name || m.name}__${m.event.name_short || m.event.name}.gpx`
      this.axios.get(m.gpx_track, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/gpx+xml' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = file_name
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    }
  },
  computed: {
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_cm: {
      get: function () {
        return this.$root.is_cm
      }
    },
  }
});
</script>

<template>

  <v-sheet v-if="event_data" class="text-no-wrap text-truncate">
    <h3>{{ event_data.name }}</h3>
  </v-sheet>

  <v-container fluid class="fill-height" style="max-width: 480px">

    <v-card density="compact" width="100%" v-if="token == null || role == null">
      <v-card-title>Zona restringida</v-card-title>
      <v-card-text class="pb-0">
        Introduzca su PIN de acceso
        <v-otp-input v-model="validation_code" inputmode="numeric" type="number" length="6"></v-otp-input>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="validation_code.length != 6" :loading="validating" class="bg-primary" @click="login_live_register" block>Validar
        </v-btn>
      </v-card-actions>
    </v-card>

    <template v-if="token && role == 'live_register' && event_data">
    <!-- <template v-if="true && event_data"> -->

      <!-- <template v-if="valid_modalities">
        {{valid_modalities[0].categories}}
        <br>
        max: {{ moment().year() - Math.min(...valid_modalities.find(m => m._id == registration.modality).categories.map(c=>c.age_from)) }}
        max: {{ moment().year() - Math.min(...valid_modalities[0].categories.map(c=>c.age_from)) }}
        <br>
        <v-text-field type="date" :max="'2000-11-11'"></v-text-field>
      </template> -->
      
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Cierre inscripciones en directo
            </v-card-title>
            <v-card-text>
              <v-table dense>
                <tr class="text-center" v-if="event_data.live_registration_per_modality" v-for="m in event_data.modalities">
                  <td class="text-left">{{m.short_name || m.name}}</td>
                  <template v-if="!m.allow_live_registration">
                    <td colspan=2 class="text-center">No habilitado</td>
                  </template>
                  <template v-else>
                    <td>{{ this.moment.utc(m.live_registration_allow_until.$date).format("DD/MM HH:mm") }}</td>
                    <td>
                      <span v-if="now < moment.utc(m.live_registration_allow_until.$date)">
                        faltan {{  $root.parse_time_from_seconds(Math.abs((now.diff(moment.utc(m.live_registration_allow_until.$date))) / 1000)) }}
                      </span>
                      <span v-else>
                        Cerrado
                      </span>
                    </td>
                  </template>
                  
                </tr>
                <tr class="text-center" v-else>
                  <td>{{ this.moment.utc(event_data.live_registration_allow_until.$date).format("DD/MM HH:mm") }}</td>
                  <td>
                    <span v-if="now < moment.utc(event_data.live_registration_allow_until.$date)">
                      faltan {{  $root.parse_time_from_seconds(Math.abs((now.diff(moment.utc(event_data.live_registration_allow_until.$date))) / 1000)) }}
                    </span>
                    <span v-else>
                      Cerrado
                    </span>
                  </td>
                </tr>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="scan"
        transition="dialog-bottom-transition"
        fullscreen>
        

        <v-card>
          <v-toolbar>
            <v-btn
              icon="fa-solid fa-close"
              @click="scan = false"
            ></v-btn>

            <v-toolbar-title>Escanear dorsal</v-toolbar-title>
          </v-toolbar>

          <v-card-subtitle>
            Escanee el código QR que aparece en el dorsal
          </v-card-subtitle>

          <v-card-text>
            <v-row>

              <v-col v-if="scan" cols="12">
                <qrcode-stream @detect="on_detect" style="width: 100%; aspect-ratio: 1;"></qrcode-stream>
              </v-col>

              <v-col cols="12" v-if="scan_error">
                <v-alert type="error">
                  <v-alert-title>Código no válido</v-alert-title>
                  <span v-if="scan_error_msg">{{ scan_error_msg }}</span>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-tabs align-tabs="center" grow v-model="tab" selected-class="bg-white">
            <v-tab value="new">Nuevo</v-tab>
            <v-tab value="registrations" :loading="loading_registrations" :disabled="registrations == null">Listado <span
                v-if="registrations !== null">({{ registrations.length }})</span></v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12">
          <v-window disabled v-model="tab" >
            <v-window-item value="new" >
              <v-row>
                <v-col cols="12">
                  <v-card density="compact">
                    <v-card-title>
                      Añadir nuevo registro
                    </v-card-title>
                    <v-card-text>
                      <v-row>

                        <v-col cols="12" v-if="!((registration.bib_number || manual) && !scan)">
                          <v-btn :disabled="scan || registration.bib_number != null" @click="scan=true" block color="primary">
                            Escanear dorsal
                          </v-btn>
                        </v-col>

                        <v-col cols="12" v-if="event_data.live_registration_allow_manual_input && !((registration.bib_number || manual) && !scan)">
                          <v-btn @click="scan=false; manual= true" :disabled="registration.bib_number" block
                                 variant="outlined">
                            Introduccion manual
                          </v-btn>
                        </v-col>

                        <v-col cols="12" v-if="registration.bib_number && !manual">
                          <v-alert type="success">
                            <v-alert-title>Código válido</v-alert-title>
                          </v-alert>
                        </v-col>

                      </v-row>

                      <v-row>

                        <v-col cols="12" v-if="(registration.bib_number || manual) && !scan">
                          <v-text-field variant="outlined"
                                        v-model="registration.bib_number" :disabled="!manual"
                                        type="number" hide-details clearable
                                        density="compact" label="Dorsal"></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="registration.bib_number">
                          <v-select :items="valid_modalities" item-title="name" item-value="_id"
                                    :disabled="event_data.modalities.length === 1"
                                    v-model="registration.modality" clearable
                                    :selectable="m => false"
                                    hide-details density="compact" variant="outlined" label="Modalidad"></v-select>
                        </v-col>


                        <v-col cols="12" v-if="registration.modality && registration.bib_number">
                          <v-checkbox v-model="no_document" @click="registration.id_number=(no_document)?null:`${event_data.numeric_id}_${registration.bib_number}`" density="compact" hide-details label="SIN documento" color="error" base-color="error"></v-checkbox>
                          <v-text-field variant="outlined" v-if="!no_document"
                                        v-model="registration.id_number" persistent-hint
                                        hint="Es muy importante utilizar un documento válido (letra incluida)"
                                        density="compact" label="Documento"></v-text-field>
                        </v-col>


                        <v-col cols="12" v-if="registration.modality && registration.id_number">
                          <v-text-field variant="outlined"
                                        v-model="registration.name" hide-details
                                        density="compact" label="Nombre"></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="registration.name != null && registration.name.length >= 3">
                          <v-text-field variant="outlined"
                                        v-model="registration.surname" hide-details
                                        density="compact" label="Apellidos"></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="constants && registration.surname != null && registration.surname.length >= 3">
                          <v-select variant="outlined" density="compact"
                                    hide-details label="Género"
                                    v-model="registration.gender"
                                    :item-title="(g) => $t(`constants.gender.${g}`)"
                                    :item-value="(g) => g"
                                    :items="constants.gender.filter(g => g != 'other')">
                          </v-select>
                        </v-col>

                        <v-col cols="12" v-if="registration.gender">
                          <v-text-field variant="outlined" type="date"
                                        v-model="registration.birthdate" hide-details
                                        density="compact" label="Fecha nacimiento"></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="clubs != null && registration.birthdate">
                          <v-combobox  clearable v-model="registration.club"
                                      density="compact" label="Club"
                                      persistent-hint
                                      hint="Busque uno de la lista, introduce uno nuevo o déjelo en blanco"
                                      variant="outlined" :items="clubs"></v-combobox>
                        </v-col>

                        <v-col cols="12" v-if="registration.birthdate">
                          <v-checkbox  v-model="registration.local"
                                      density="compact" label="Participante local"
                                      persistent-hint
                                      hint="Marque si el participante debe ser incluido en las clasificaciones locales (en caso de haberlas)"
                                      variant="outlined"></v-checkbox>
                        </v-col>

                        <v-col cols="12">
                          <v-btn v-if="registration.birthdate" :loading="adding" @click="add_new_register" block color="primary">Añadir
                          </v-btn>
                        </v-col>

                        <v-col cols="12" v-if="add_error != ''">
                          <v-alert type="error">
                            <v-alert-title>Error en la inscripcion</v-alert-title>
                            {{ add_error }}
                          </v-alert>
                        </v-col>


                      </v-row>


                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item value="registrations">
              <v-table density="compact">
                <thead>
                <tr>
                  <td>Dorsal</td>
                  <td>Participante</td>
                  <td>Modalidad</td>
                  <td class="hidden-xs">Hora</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="r in registrations">
                  <td>{{ r.bib_number }}</td>
                  <td>{{ r.name }} {{ r.surname }}</td>
                  <td>{{ r.modality.name }}</td>
                  <td class="hidden-xs">{{ moment(r.created_at.$date).format("DD/MM HH:mm") }}</td>
                </tr>
                </tbody>
              </v-table>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>

    </template>

  </v-container>

</template>


<script>
import {QrcodeStream, QrcodeDropZone, QrcodeCapture} from 'vue-qrcode-reader'
import {defineComponent} from 'vue';
import router from "@/router";
import RegistrationsTable from "@/components/RegistrationsTable.vue"
import moment, { now } from 'moment';

export default defineComponent({
  name: 'LiveRegistration',
  components: {
    RegistrationsTable,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  data: () => ({
    now: null,
    validating: false,
    live_registration_token: null,
    validation_code: '',
    role: null,
    token: null,
    tab: 'new',
    event_data: null,
    valid_modalities: null,
    event_stats: null,
    registrations: null,
    loading_registrations: false,
    registration_stats: null,
    clubs: null,
    scan: false,
    scan_error: false,
    scan_error_msg: null,
    manual: false,
    no_document: false,
    registration_base: {
      source: 'live_register',
      modality: null,
      bib_number: null,
      id_number: null,
      name: null,
      surname: null,
      gender: null,
      birthdate: null,
      club: null,
      local: false,
    },
    registration: null,
    adding: false,
    add_error: "",
  }),
  created() {
    this.now = this.moment().utc(true)
    setInterval(() => {
      this.now = this.moment().utc(true)
    }, 1000)

    this.live_registration_token = this.$route.params.token
    this.registration = {...this.registration_base}

    let validation_codes = this.$store.getters.validation_codes

    if ( validation_codes && this.live_registration_token in validation_codes ) {
      this.validation_code = validation_codes[this.live_registration_token]
    }
    this.fetch_event_data()
  },
  methods: {
    add_new_register() {
      this.add_error = ""
      this.adding = true

      const headers = {'authorization': this.token}

      // let registration = {...this.registration}
      // registration['modality'] = registration['modality']['$oid']

      if ( this.no_document ) {
        this.registration['valid_id'] = false
      }

      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registration/live-register`,
          {"registration": this.registration},
          {headers: headers},
      ).then((response) => {
        this.manual = false
        this.registration = {...this.registration_base}
        this.no_document = false
        this.fetch_event_data()
        this.fetch_live_registrations()
      }).catch((error) => {
        console.error(error)
        if (error.response.status === 401) {
          router.push({name: 'Home'})
        } else if (error.response.status === 502) {
          this.add_error = "El dorsal ya existe en la base de datos"
        } else if (error.response.status === 503) {
          this.add_error = "Inscripción no permitida para la modalidad"
        } else {
          this.add_error = "Error desconocido"
        }
      }).finally(() => {
        this.adding = false
      })
    },
    check_bib() {
      console.log("chekcing")
      console.log(this.registration)
    },
    fetch_event_data() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {'live_registration_token': this.live_registration_token}
      ).then((response) => {
        this.event_data = response.data[0]

        // Check if live registration is allowed
        if (!this.event_data.allow_live_registration) {
          router.push({name: 'Home'})
        }
        
        // Filter modalities that allow live registration
        this.valid_modalities = this.event_data.modalities.filter(m => m.allow_live_registration === true)

        // Check limit datetime
        if ( !this.event_data.live_registration_per_modality ) {
          if (this.moment.now() > this.moment(this.event_data.live_registration_allow_until.$date)) {
            router.push({name: 'Home'})
          }
        } else {
          this.valid_modalities = this.valid_modalities.filter(m => this.now < this.moment.utc(m.live_registration_allow_until.$date))
          if ( this.valid_modalities.length === 0 ) {
            router.push({name: 'Home'})
          }
        }

        // Set default modality if only one
        if (this.event_data.modalities.length === 1) {
          this.registration.modality = this.event_data.modalities[0]._id
        }
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        if (this.event_data == null) {
          router.push({name: 'Home'})
        } else {
          this.fetch_registrations_stats()
          this.fetch_live_registrations()
        }
      })
    },
    fetch_live_registrations() {
      this.loading_registrations = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          {
            "event": this.event_data.slug,
            "limit": 150,
            "source": 'live_register',
            "order": ["-created_at"]
          }
      ).then((response) => {
        this.registrations = response.data
        this.loading_registrations = false
      }).catch((error) => {
        console.error(error)
        this.loading_registrations = false
      })
    },
    fetch_registrations_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          {
            "event": this.event_data.slug,
          }
      ).then((response) => {
        this.registration_stats = response.data
        this.clubs = Object.keys(this.registration_stats.club)
      }).catch((error) => {
        console.error(error)
      })
    },
    on_detect(text) {
    console.log(text)
      if (text.length === 1) {
        this.scan_error = false
        this.scan_error_msg = null

        let url = text[0].rawValue;
        const regex = /\/r\/(\d+)-(\d+)(?:\?[^#]*&?mod=(\d+))?/;
        const matches = url.match(regex);

        if (matches) {
          const event_id = matches[1];
          const bib = matches[2];
          const modality_id = matches[3] || null;

          if (event_id == this.event_data.numeric_id) {
            this.registration.bib_number = bib
            if ( modality_id ) {
              let mod = this.valid_modalities.find(m => m.numeric_id == modality_id)
              if ( mod ) {
                this.registration.modality = mod._id
                this.scan = false
              } else {
                this.scan_error = true
                this.scan_error_msg = "Esta modalidad no permite inscripciones en directo"
              }
            } else {
              this.scan = false
            }
          } else {
            this.scan_error = true
            this.scan_error_msg = "Este dorsal no es válido para la carrera"
          }
        } else {
          this.scan_error = true
        }

      } else {
        this.scan_error = true
        this.scan_error_msg = "Se ha detectado más de un código"
      }
    },
    login_live_register() {
      this.validating = true
      if (this.validation_code == null || this.validation_code.length != 6) {
        console.error("Wrong validation code format")
      } else {
        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/login/live-register`,
            {
              "token": this.live_registration_token,
              "password": this.validation_code,
            }
        ).then((response) => {
          this.role = response.data['role']
          this.token = response.data['token']
          this.$store.commit('changeValidationCodes', {event_token: this.live_registration_token, pin: this.validation_code})
          this.validating = false
          this.fetch_event_data()
        }).catch((error) => {
          router.push({name: 'Home'})
        })
      }
    }
  },

  computed: {
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    }
  }

});
</script>

export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bienvenido"])},
  "event": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ningún evento"]), _normalize(["evento"]), _normalize(["eventos"])])},
  "slogan": {
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sumando"])},
    "together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juntos"])}
  },
  "constants": {
    "event_status": {
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelado"])},
      "registrations_not_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripciones no abiertas"])},
      "registrations_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripciones abiertas"])},
      "registrations_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripciones agotadas"])},
      "registrations_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripciones cerradas"])},
      "waiting_for_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esperando a empezar"])},
      "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en directo"])},
      "finished_provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalizado"])},
      "finished_official": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalizado"])}
    },
    "sport": {
      "walking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caminar"])},
      "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correr"])},
      "trail_running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correr por montaña (trail)"])},
      "hiking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sendersimo"])},
      "time_trial_climb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cronoescalada"])},
      "cycling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciclismo"])},
      "btt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bicicleta por montaña (BTT)"])},
      "stages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por etapas"])},
      "biathlon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biatlón"])},
      "triathlon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["triatlón"])},
      "swimming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["natación"])},
      "open_waters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aguas abiertas"])},
      "relays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relevos"])},
      "climbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["escalada"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otros"])},
      "roller_skating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patinaje sobre ruedas"])},
      "nordic_walking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marcha nórdica"])}
    },
    "chronotype": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos"])},
      "individual_chrono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrarreloj"])},
      "batched_chrono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salidas por tandas"])}
    },
    "gender": {
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masculino"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["femenino"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otro"])}
    },
    "extra_services": {
      "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["café"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agua"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comida sólida"])},
      "energy_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["barritas energéticas"])},
      "shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ducha"])},
      "beer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cerveza"])},
      "vegetarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comida vegetariana"])},
      "vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comida vegana"])},
      "gluten_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comida sin gluten"])},
      "retirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punto de retirada"])},
      "life_bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punto de bolsa de vida"])},
      "shelter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refugio"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lugar para dormir"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["médico"])},
      "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traslado con transporte"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otro"])},
      "assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punto de asistencia"])}
    },
    "classification_type": {
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equipos"])}
    }
  },
  "_event": {
    "circuit_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este evento es parte del circuit"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscríbete"])},
    "registration_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["listado de inscritos"])},
    "edit_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar  inscripción"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha"])},
    "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["federación"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organización"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacto"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentos"])},
    "modality": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["a"]), _normalize(["modalidad"]), _normalize(["modalitades"])])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["información"])},
    "error_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esta opción ya no está disponible"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lugar"])},
    "datetime_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a las"])}
  },
  "result_status": {
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalizado"])},
    "out_of_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuera de tiempo"])},
    "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retirado"])},
    "disqualified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descalificado"])},
    "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no empieza"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empezado"])}
  },
  "_modality": {
    
  },
  "language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "page_titles": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario de eventos"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
    "registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de inscritos"])},
    "event_list": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario de eventos"])},
      "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])}
    }
  },
  "_event_list": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
    "filter_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtros aplicados"])},
    "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar filtros"])},
    "hide_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ocultar filtros"])},
    "filters_name": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por nombre"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por deporte"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por fecha"])},
      "date_values": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todas"])},
        "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta dentro de un mes"])},
        "three_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta dentro de 3 meses"])},
        "six_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta dentro de medio año"])},
        "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta dentro de un año"])}
      },
      "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por distancia"])},
      "slider": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cualquiera"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desde"])},
        "more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más de"])}
      },
      "slope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desnivel positivo"])},
      "nearby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca de mi"])},
      "nearby_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa los servicios de localización para utilizar este filtro"])}
    }
  },
  "units": {
    "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métrico"])},
    "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
    "distance": {
      "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kilómetros"])},
      "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["millas"])}
    }
  }
}
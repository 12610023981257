<template>

  <v-breadcrumbs bg-color="primary" density="compact" v-if="registration">

    <v-breadcrumbs-item :to="{ name: 'AdminEvent', params: { event_id: registration.modality.event.slug }}"
                        class="d-inline-block text-truncate">
      {{ registration.modality.event.name_short || registration.modality.event.name }}
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider></v-breadcrumbs-divider>

    <v-breadcrumbs-item class="d-inline-block text-truncate"
                        :to="{ name: 'AdminEvent', params: { event_id: registration.modality.event.slug, modality_selected: registration.modality._id.$oid }}">
      {{ registration.modality.short_name || registration.modality.name }}
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider></v-breadcrumbs-divider>

    <v-breadcrumbs-item class="d-inline-block text-truncate"
                        :to="{name: 'AdminRegistrationsList', params: { event_id: registration.modality.event._id.$oid }}">
      Inscripciones
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider></v-breadcrumbs-divider>

    <v-breadcrumbs-item class="d-inline-block text-truncate">
      {{ registration.name }} {{ registration.surname }}
    </v-breadcrumbs-item>

  </v-breadcrumbs>

  <v-container style="max-width: 960px;">

    <v-card>

      <v-toolbar color="primary">
        <v-toolbar-title>Editar registro</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn size="small" variant="text" stacked disabled>
            <v-icon size="small" icon="fa-solid fa-save"></v-icon>
            <span class="hidden-xs mt-1">Guardar</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-subtitle v-if="registration">
        <span v-if="registration.user">Usuario asociado a esta inscripción: <router-link to="">Pepito</router-link></span>
        <span v-else>No hay ningun usuario asociado a esta inscripción</span>
      </v-card-subtitle>

<!--      TODO: Missing: source, creation, created and results-->

      <v-card-title v-if="registration && registration.extra_questions">Datos personales</v-card-title>

      <v-card-text>
        <v-row v-if="registration">

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Nombre" variant="outlined" v-model="registration.name">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field hide-details density="compact"
                          label="Apellidos" variant="outlined" v-model="registration.surname">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="ID" variant="outlined" v-model="registration.id_number">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact" type="date"
                          label="Fecha nacimiento" variant="outlined" v-model="registration.birthdate">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-select v-if="constants" variant="outlined" density="compact"
                      hide-details
                      label="Género"
                      v-model="registration.gender"
                      :items="constants.gender">
            </v-select>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact" v-if="registration.contact && registration.contact.email"
                          label="e-mail" variant="outlined" v-model="registration.contact.email">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact" v-if="registration.contact && registration.contact.phone"
                          label="teléfono" variant="outlined" v-model="registration.contact.phone">
            </v-text-field>
          </v-col>

        </v-row>


      </v-card-text>

      <v-divider></v-divider>

      <v-card-title v-if="registration && registration.extra_questions">Datos de la inscripción</v-card-title>

      <v-card-text>
        <v-row v-if="registration">

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Dorsal" variant="outlined" v-model="registration.bib_number">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Categoría" variant="outlined" v-model="registration.category">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Club" variant="outlined" v-model="registration.club">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Equipo" variant="outlined" v-model="registration.team">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Federación" variant="outlined" v-model="registration.federation">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-switch hide-details density="compact" color="primary"
                      label="Clasifica" variant="outlined" v-model="registration.classify">
            </v-switch>
          </v-col>

          <v-col cols="12" sm="3">
            <v-switch hide-details density="compact" color="primary"
                      label="Local" variant="outlined" v-model="registration.local">
            </v-switch>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="Precio inscripción" variant="outlined" v-model="registration.price_paid">
            </v-text-field>
          </v-col>

        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title v-if="registration && registration.extra_questions">Preguntas de la inscripción</v-card-title>

      <v-card-text v-if="registration && registration.extra_questions">
        <v-row>
          <v-col cols="12" sm="6" v-for="(q, a) in registration.extra_questions">
            <v-text-field hide-details density="compact"
                          :label="a" variant="outlined" v-model="registration.extra_questions[a]">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import RegistrationsTable from "@/components/RegistrationsTable.vue";

export default defineComponent({
  name: 'AdminRegistration',
  components: {
    RegistrationsTable,
  },
  data: () => ({
    now: null,
    registration_id: null,
    registration: null,
    create: true,
    registrations: null,
  }),
  created() {
    this.registration_id = this.$route.params.registration_id
    this.now = this.moment()
    document.title = "Edit registration"
    this.get_registrations()
  },
  methods: {
    get_registrations() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations?t=${this.moment.now()}`,
          {
            "id": this.registration_id,
          }
      ).then((response) => {
        this.registration = response.data[0]
        if (Object.hasOwn(this.registration, 'birthdate')) {
          if (this.registration.birthdate.$date.$numberLong) {
            this.registration.birthdate = this.moment.utc(new Date(parseInt(this.registration.birthdate.$date.$numberLong)).toISOString()).format('YYYY-MM-DD')
          } else {
            this.registration.birthdate = this.moment.utc(this.registration.birthdate.$date).format('YYYY-MM-DD')
          }
        }
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },

    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_users()
      }
    },
  }
});
</script>

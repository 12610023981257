<template>
  <template v-if="is_production">
    <ins class="adsbygoogle"
         style="display:block"
         data-ad-client="ca-pub-9421258775689022"
         :data-ad-slot="ad_slot"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  </template>
  <template v-else>
    <v-sheet class="d-flex align-center justify-center" height="200px" color="grey">
      Publi
    </v-sheet>
  </template>
</template>

<script>
import moment from "moment/moment";
import {SUPPORTED_LANGUAGES, SUPPORTED_UNITS} from "@/locales/constants";
import axios from "axios";

export default {
  name: "AdSense",
  props: {
    "ad_slot": {default: 1348879686}
  },
  data: () => ({
    is_production: process.env.NODE_ENV === 'production',
  }),
  mounted() {
    if ( this.is_production ) {
      let adsbygoogle
      adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    }
  }
}
</script>
<template>

  <v-breadcrumbs color="" bg-color="primary" density="compact" >
      <v-breadcrumbs-item v-if="event" :to="{ name: 'Event', params: { event_id: event.slug }}"
                          class=" text-truncate hidden-xs">
        {{ event.name_short || event.name }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider class="hidden-xs" v-if="event"></v-breadcrumbs-divider>

    <v-breadcrumbs-item v-if="modality" :to="{ name: 'Modality', params: { modality_id: modality._id.$oid }}"
                        class=" text-truncate">
      {{ modality.short_name || modality.name }}
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider class="hidden-xs" v-if="modality"></v-breadcrumbs-divider>

    <v-breadcrumbs-item v-if="result" class=" hidden-xs">
      <span>
        {{ result.name }} <b class="text-uppercase text-no-wrap">{{ result.surname }}</b>
      </span>
    </v-breadcrumbs-item>

  </v-breadcrumbs>

  <v-container fluid style="max-width: 650px">

    <v-row align="center" justify="center" id="to_print">
      <v-col class="">
        <v-card :loading="loading_result" v-if="result" id="print_diploma">
          <v-img class="w-100" cover lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
                 :src="result.modality.event.poster"
                 height="130px" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)">
            <div class="d-flex flex-column fill-height justify-center align-center text-white">
              <span class="text-h4">
                {{ result.name }} <br class="hidden-sm-and-up"><b class="text-uppercase text-no-wrap">{{ result.surname }}</b>
              </span>
              <span class="text-body-2" v-if="result.club">
                {{ result.club }}
              </span>
              <v-chip label size="small" variant="flat" class="text-body-2" v-if="result.federation">
                {{ result.federation }}
              </v-chip>
            </div>
          </v-img>
          <v-card-subtitle class="bg-tertiary">
            <v-row>
              <v-col><span v-if="modality.datetime_start">{{ moment.utc(modality.datetime_start.$date).format("LL") }}</span></v-col>
              <v-col class="text-right">
                <span v-if="event.location">{{ event.location.city }}
                  <span class="hidden-xs">, {{event.location.county }}</span>
                </span>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-title class="bg-primary text-center">{{ event.name_short || event.name }}</v-card-title>
          <v-card-subtitle class="bg-primary text-center" v-if="modality.event.circuit">
            <v-progress-linear indeterminate height="8" v-if="loading_circuit"></v-progress-linear>
            <span v-else>{{circuit.name}}</span>
          </v-card-subtitle>
          <!--          <v-card-subtitle class="pb-2 pt-2 text-center text-h6 bg-primary">{{ modality.name }}</v-card-subtitle>-->
          <v-card-text class="mt-4">


            <v-row>
              <v-col class="text-center" cols="6" sm="4" md="3">
                <span class="text-caption text-capitalize">Modalidad</span>
                <h2 class="text-capitalize">{{ modality.short_name || modality.name }}</h2>
              </v-col>
              <v-col class="text-center" cols="6" sm="4" md="3">
                <span class="text-caption text-capitalize">Distancia</span>
                  <h2>{{ $root.parse_distance(modality.distance, 1, 1) }}
                  <span v-if="modality.slope">{{ modality.slope }}m+</span>
                </h2>
              </v-col>
              <v-col class="text-center" cols="6" sm="4" md="3">
                <span class="text-caption text-capitalize">Estado</span>
                <template v-if="result.result">
                  <h2 class="text-uppercase text-body-1 font-weight-bold">
                    <span :class="result.result.status == 'disqualified' ? 'text-error' : ''">{{ $t(`result_status.${result.result.status}`) }}</span>
                  </h2>
                  <v-progress-linear v-if="result.result.status == 'started'" indeterminate></v-progress-linear>
                  <v-progress-linear v-if="result.result.status == 'started'" :model-value="100*last_intermediate_distance/modality.distance"></v-progress-linear>
                  <span class="text-caption font-italic" v-if="result.result.status == 'started'">
                    {{ $root.parse_distance(last_intermediate_distance, 1, 1) }} de {{ $root.parse_distance(modality.distance, 1, 1) }}
                  </span>
                </template>
                <template v-else>
                  <h2>Esperando</h2>
                  <span class="text-caption" v-if="result.modality.type == 'all'">
                    Hora de salida: <br><b>{{moment.utc(result.modality.datetime_start.$date).format('DD/MM HH:mm')}}</b>
                  </span>
                </template>
              </v-col>
              <v-col class="text-center" cols="6" sm="4" md="3">
                <span class="text-caption text-capitalize">Dorsal</span>
                <h2 class="text-uppercase">{{ result.bib_number }}</h2>
              </v-col>
              <v-col class="text-center" cols="6" sm="4" md="3" v-if="result.result && ['started', 'finished'].includes(result.result.status)">
                <span class="text-caption text-capitalize">
                  <span v-if="result.result.status == 'finished'">Tiempo oficial</span>
                  <span v-else>Tiempo carrera</span>
                  <template v-if="result.result.penalizations.length > 0" class="d-block">
                    <br>
                    <v-icon start size="x-small" icon="fa-solid fa-circle-exclamation" color="error"></v-icon>
                    <span class="text-error text-caption">Penalizado</span>
                  </template>
                </span>
                <h2 v-if="result.result.status == 'finished'" :class="result.result.penalizations.length > 0 ? 'text-error' : ''">
                  {{ $root.parse_time_from_seconds(result.result.total_time) }}
                </h2>
                <h2 v-else>
                  <span v-if="result.result && result.result.start_time">
                    {{$root.parse_time_from_seconds((now.diff(moment(result.result.start_time.$date)))/1000)}}
                  </span>
                </h2>
                <span class="text-caption font-italic" v-if="result.result.total_time_real && result.result.total_time_real < result.result.total_time">
                  T. Real: {{ $root.parse_time_from_seconds(result.result.total_time_real) }}
                  <br>
                </span>
                <span v-if="result.result.total_time" :class="result.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
                    {{$root.get_speed_and_units(modality.sport, modality.distance, result.result.total_time)}}
                  </span>
              </v-col>

              {{ void(non_accumulative_prize_discarder = Object.keys(result.result).some(key => modality.prizes.some(prize => key === `pos_${prize.by}`) && !(key in result.result.prizes))) }}

              <template v-if="modality.show_prized_positions">
                <template v-for="prize in modality.prizes">
                  <v-col class="text-center" cols="6" sm="4" md="3" v-if="result.result && result.result.status == 'finished' && result.classify && (prize.by.startsWith('local') ? result.local : true)">
                    <span class="text-caption text-capitalize">Posición 
                      <span v-if="prize.by == 'general'">general</span>
                      <span v-if="prize.by == 'gender'">género {{ $t(`constants.gender.${result.gender}`) }}</span>
                      <span v-if="prize.by == 'category'">categoría</span>
                      <span v-if="prize.by == 'local'">local</span>
                      <span v-if="prize.by == 'local_gender'">local género {{ $t(`constants.gender.${result.gender}`) }}</span>
                      <span v-if="prize.by == 'local_category'">local categoría</span>
                    </span>
                    <h2>
                      <v-chip v-if="result.result.prizes.which_prizes && result.result.prizes.which_prizes[`pos_${prize.by}`]" variant="elevated" size="x-small"
                        :color="['gold', 'silver', 'bronze'].at(result.result.prizes[`pos_${prize.by}`]-1)">
                        <v-icon start size="small" icon="fa-solid fa-trophy"></v-icon>
                        <b>{{ result.result.prizes[`pos_${prize.by}`] }}</b>
                        <span class="ml-1" v-if="prize.by.includes('category')">{{ result.category }}</span>
                      </v-chip>
                      <span v-else>
                        <span v-if="result.result.prizes[`pos_${prize.by}`]">{{ result.result.prizes[`pos_${prize.by}`] }}</span>
                        <!-- No acumulado: -->
                        <span v-else class="font-italic mr-1">{{ result.result[`pos_${prize.by}`] }}<span class="text-red">*</span></span>
                        <span class="ml-1" v-if="prize.by.includes('category')">{{ result.category }}</span>
                      </span>
                    </h2>
                    <span class="text-caption font-italic" v-if="stats && !prize.by.includes('local')">de
                      <span v-if="prize.by == 'general'">{{stats.total}}</span>
                      <span v-if="prize.by == 'gender'">{{ stats.gender[result.gender] }}</span>
                      <span v-if="prize.by == 'category'">{{stats.category[result.category]}}</span>
                    </span>
                  </v-col>
                </template>

              </template>
              <template v-else>

                <v-col class="text-center" cols="6" sm="4" md="3" v-if="result.result && result.result.status == 'finished' && result.result.pos_general">
                  <span class="text-caption text-capitalize">Posición general</span>
                  <h2>
                    {{ result.result.pos_general }}
                  </h2>
                  <span class="text-caption font-italic" v-if="stats">de {{ stats.total }}</span>
                </v-col>

                <v-col class="text-center" cols="6" sm="4" md="3" v-if="result.result && result.result.status == 'finished' && result.result.pos_gender">
                  <span class="text-caption text-capitalize">Posición género {{ $t(`constants.gender.${result.gender}`) }}</span>
                  <h2>
                    <v-chip v-if="result.result.pos_gender <= 3" variant="elevated" size="small"
                            :color="['gold', 'silver', 'bronze'].at(result.result.pos_gender-1)">
                      <v-icon start size="small" icon="fa-solid fa-trophy"></v-icon>
                      <b>{{ result.result.pos_gender }}</b>
                    </v-chip>
                    <span v-else>{{ result.result.pos_gender }}</span>
                  </h2>
                  <span class="text-caption font-italic" v-if="stats">de {{ stats.gender[result.gender] }}</span>
                </v-col>

                <v-col class="text-center" v-if="result.result && result.result.status == 'finished' && result.result.pos_category" cols="6" sm="4" md="3">
                  <span class="text-caption text-capitalize">Posición Categoria</span>
                  <h2>
                    <v-chip v-if="result.result.pos_category <= 3" variant="elevated" size="small"
                            :color="['gold', 'silver', 'bronze'].at(result.result.pos_category-1)">
                      <v-icon start size="small" icon="fa-solid fa-trophy"></v-icon>
                      <b>{{ result.result.pos_category }}</b><span class="ml-1">{{ result.category }}</span>
                    </v-chip>
                    <span v-else>{{ result.result.pos_category }} {{ result.category }}</span>
                  </h2>
                  <span class="text-caption font-italic" v-if="stats">de {{ stats.category[result.category] }}</span>
                </v-col>

                <v-col class="text-center" v-if="result.result && result.result.status == 'finished' && result.result.pos_local_gender" cols="6" sm="4" md="3">
                  <span class="text-caption text-capitalize">Local Género {{ $t(`constants.gender.${result.gender}`) }}</span>
                  <h2>
                    <span>{{ result.result.pos_local_gender }}</span>
                  </h2>
                </v-col>

                <v-col class="text-center" v-if="result.result && result.result.status == 'finished' && result.result.pos_local_category" cols="6" sm="4" md="3">
                  <span class="text-caption text-capitalize">Local Categoría</span>
                  <h2>
                    <span>{{ result.result.pos_local_category }} {{ result.category }}</span>
                  </h2>
                </v-col>
              </template>

              <template v-if="modality.circuit_modality != null && circuit_score && circuit_score.race_results && circuit_score.race_results[modality._id.$oid]">

                <v-col class="text-center" v-if="result.result && result.result.status == 'finished' && circuit_score.race_results[modality._id.$oid].score" cols="6" sm="4" md="3">
                  <span class="text-caption text-capitalize">Circuito puntos género {{ $t(`constants.gender.${result.gender}`) }}</span>
                  <h2>
                    <span>{{ circuit_score.race_results[modality._id.$oid].score }}</span> <span class="text-caption">pts</span>
                  </h2>
                </v-col>

                <v-col class="text-center" v-if="result.result && result.result.status == 'finished' && circuit_score.race_results[modality._id.$oid].score_cat" cols="6" sm="4" md="3">
                  <span class="text-caption text-capitalize">Circuito puntos categoría</span>
                  <h2>
                    <span>{{ circuit_score.race_results[modality._id.$oid].score_cat }}</span> <span class="text-caption">pts</span>
                  </h2>
                  <span>{{ result.category }}</span>
                </v-col>

              </template>

              <v-col v-if="non_accumulative_prize_discarder" cols="12" class="text-center">
                <span class="text-caption text-red">*no obtiene trofeo dado que ya ha obtenido un premio mayor y estos no son acumulativos</span>
              </v-col>

            </v-row>

            <v-row align-content="center" justify="center" v-if="result.result && result.result.penalizations.length > 0">
              <v-col cols="12">
                <v-alert color="error" variant="tonal">
                  <span>Penalizaciones</span>
                    <span v-if="result.result.total_time_dsq"><br><b class="ml-2">{{$root.parse_time_from_seconds(result.result.total_time_dsq)}}</b>: Tiempo en meta</span>
                    <span v-for="p in result.result.penalizations">
                      <br><b v-if="p.time">+{{$root.parse_time_from_seconds(p.time)}}: </b>{{p.reason}}
                    </span>
                  <span v-if="result.result.total_time_dsq"><br><b>={{$root.parse_time_from_seconds(result.result.total_time)}}</b>: Tiempo oficial</span>
                </v-alert>
              </v-col>
            </v-row>

            <v-row align-content="center" justify="center" v-if="result.result && result.result.disqualified_reason">
              <v-col cols="12">
                <v-alert color="error" variant="tonal">
                  <span>Motivo descalificación</span>
                  <v-alert-title>{{result.result.disqualified_reason}}</v-alert-title>
                </v-alert>
              </v-col>
            </v-row>

            <v-row align-content="center" justify="center" v-if="modality.show_prized_positions && result.result.status == 'finished' && result.result.prizes.has_prize">
              <v-col cols="12">
                <v-alert icon="fa-solid fa-trophy" color="success" :variant="modality.status == 'live' ? 'elevated' : 'outlined'">
                  <v-alert-title>¡Enhorabuena! ¡{{ (modality.status == 'live') ? 'Obtienes' : 'Obtuviste' }} trofeo!</v-alert-title>
                    De acuerdo con el reglamento, {{ (modality.status == 'live') ? 'obtienes' : 'obtuviste' }} {{ Object.values(result.result.prizes.which_prizes).filter(value => value === true).length }} trofeos:
                    <ul>
                      <li class="ml-2" v-for="prize in Object.keys(result.result.prizes.which_prizes).filter(key => result.result.prizes.which_prizes[key] === true)">
                        <span class="text-body-1">
                          - 
                          <span>
                            {{ moment().localeData().ordinal(result.result.prizes[prize]) }}
                            <span v-if="prize == 'pos_general'">general</span>
                            <span v-if="prize == 'pos_gender'">{{ $t(`constants.gender.${result.gender}`) }}</span>
                            <span v-if="prize == 'pos_category'">categoría {{ result.category }}</span>
                            <span v-if="prize == 'pos_local'">local general</span>
                            <span v-if="prize == 'pos_local_gender'">local {{ $t(`constants.gender.${result.gender}`) }}</span>
                            <span v-if="prize == 'pos_local_category'">local categoría {{ result.category }}</span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <template v-if="modality.status == 'live'">
                      Rogamos estés atento a la entrega de trofeos.
                      <div class="pt-4 font-italic text-black"><v-icon icon="fa-solid fa-triangle-exclamation" start size="x-small"></v-icon>Esta información es provisional y podría ser modificada.</div>
                    </template>
                </v-alert>
              </v-col>
            </v-row>

            <v-row v-if="modality.gpx_track">
              <v-col>
                <v-sheet height="150px">
                  <MychipMap :modality="modality" :show_map="false"
                             :participant="result"
                             :adjust_to_width="true"></MychipMap>
                </v-sheet>
                <div class="text-right" v-if="last_positions !== null">

                  <v-dialog fullscreen scrim
                            transition="dialog-bottom-transition" scrollable
                            width="100%">

                    <template v-slot:activator="{ props }">
                      <v-btn variant="text" size="x-small" v-bind="props">Ver posicion en mapa</v-btn>
                    </template>

                    <template v-slot:default="{ isActive }">

                      <v-toolbar color="primary">

                        <v-toolbar-title>
                          Mapa
                          <h3>{{ modality.short_name || modality.name }}</h3>
                        </v-toolbar-title>
                        <v-toolbar-items>
                          <v-btn @click="isActive.value = false" icon>
                            <v-icon icon="fa-solid fa-times"></v-icon>
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>
                      <MychipMap style="height: 100%; width: 100%" :modality="modality" :participant="result"></MychipMap>
                    </template>

                  </v-dialog>
                </div>
              </v-col>
            </v-row>

            <v-alert class="mt-6" density="compact" 
                    variant="outlined" type="info"
                    v-if="modality && modality.show_prized_positions && modality.prizes.some(p => p.non_cummulative_with.length > 0)">
              Las posiciones mostradas a continuación son las posiciones calculadas sin tener en cuenta los premios no acumulativos.
              Para saber si ha obtenido trofeo, consulte la información de la parte superior.
            </v-alert>

            <v-table density="compact" class="pt-0" v-if="result.result">
              <thead>
              <tr class="text-uppercase text-caption">
                <th></th>
                <th class="hidden-xs">punto</th>
                <th>tiempo</th>
                <th class="hidden-xs">ritmo</th>
                <th>pos</th>
                <th>pos cat</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $root.parse_distance(0, 0, 1) }}</td>
                <td class="hidden-xs">Salida</td>
                <td>
                  <span v-if="result.result.total_time && result.result.total_time_real && result.result.total_time_real < result.result.total_time">
                    {{ $root.parse_time_from_seconds(result.result.total_time - result.result.total_time_real)}}<v-icon end size="x-small" icon="fa-solid fa-circle-info"></v-icon>
                    <v-tooltip activator="parent" location="bottom">
                      Pasa por línea de meta {{ moment.duration(result.result.total_time - result.result.total_time_real, 'seconds').humanize() }} después de la salida oficial
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ $root.parse_time_from_seconds(0) }}
                  </span>
                </td>
                <td class="hidden-xs"></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="i in modality.intermediates">
                <td>
                  <div class="mb-0 pb-0">{{ $root.parse_distance(i.distance_from_start, 1, 1) }}</div>
                </td>
                <td class="hidden-xs">{{ i.location.custom_name }}</td>
                <td>
                  <span v-if="result.result.intermediate_results[i.order] && result.result.intermediate_results[i.order].total_time" :class="result.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
                    {{$root.parse_time_from_seconds(result.result.intermediate_results[i.order].total_time)}}
                  </span>
                  <span v-if="i.order == last_intermediate + 1 && result.result.status == 'started'">
                    <v-progress-linear indeterminate></v-progress-linear>
                  </span>
                </td>
                <td class="hidden-xs">
                  <span v-if="result.result.intermediate_results[i.order] && modality.intermediates[i.order] && result.result.intermediate_results[i.order].total_time" :class="result.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
                    {{$root.get_speed_and_units(modality.sport, modality.intermediates[i.order].distance_from_start, result.result.intermediate_results[i.order].total_time)}}
                  </span>
                </td>
                <td>
                  <span v-if="result.result.intermediate_results[i.order] && result.result.intermediate_results[i.order].pos_gender" class="text-no-wrap">
                      {{result.result.intermediate_results[i.order].pos_gender}}
                    <span v-if="result.result.intermediate_results[i.order-1] && result.result.intermediate_results[i.order-1].pos_gender">
                      <v-icon v-if="result.result.intermediate_results[i.order-1].pos_gender > result.result.intermediate_results[i.order].pos_gender"
                              icon="fa-solid fa-caret-up" size="x-small" color="success"></v-icon>
                      <v-icon v-else-if="result.result.intermediate_results[i.order-1].pos_gender < result.result.intermediate_results[i.order].pos_gender"
                              icon="fa-solid fa-caret-down" size="x-small" color="error"></v-icon>
                      <v-icon v-else icon="fa-solid fa-caret-left" size="x-small" color="grey"></v-icon>
                    </span>
                  </span>
                </td>
                <td>
                  <span v-if="result.result.intermediate_results[i.order] && result.result.intermediate_results[i.order].pos_category" class="text-no-wrap">
                    {{result.result.intermediate_results[i.order].pos_category}}
                    <span v-if="result.result.intermediate_results[i.order-1] && result.result.intermediate_results[i.order-1].pos_category">
                      <v-icon v-if="result.result.intermediate_results[i.order-1].pos_category > result.result.intermediate_results[i.order].pos_category"
                              icon="fa-solid fa-caret-up" size="x-small" color="success"></v-icon>
                      <v-icon v-else-if="result.result.intermediate_results[i.order-1].pos_category < result.result.intermediate_results[i.order].pos_category"
                              icon="fa-solid fa-caret-down" size="x-small" color="error"></v-icon>
                      <v-icon v-else icon="fa-solid fa-caret-left" size="x-small" color="grey"></v-icon>
                    </span>
                  </span>
                </td>
              </tr>

              <tr>
                <td>{{ $root.parse_distance(modality.distance, 1, 1) }}</td>
                <td class="hidden-xs">Meta</td>
                <td>
                  <span v-if="result.result.total_time" :class="result.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
                    {{$root.parse_time_from_seconds(result.result.total_time)}}
                  </span>
                </td>
                <td class="hidden-xs">
                  <span v-if="result.result.total_time" :class="result.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
                    {{$root.get_speed_and_units(modality.sport, modality.distance, result.result.total_time)}}
                  </span>
                </td>
                <td>
                  <span>
                    {{result.result.pos_gender}}
                    <span v-if="result.result.pos_gender && result.result.intermediate_results[modality.intermediates.length - 1]">
                      <v-icon v-if="result.result.intermediate_results[result.result.intermediate_results.length - 1].pos_gender > result.result.pos_gender"
                              icon="fa-solid fa-caret-up" size="x-small" color="success"></v-icon>
                      <v-icon v-else-if="result.result.intermediate_results[result.result.intermediate_results.length - 1].pos_gender < result.result.pos_gender"
                              icon="fa-solid fa-caret-down" size="x-small" color="error"></v-icon>
                      <v-icon v-else icon="fa-solid fa-caret-left" size="x-small" color="grey"></v-icon>
                    </span>
                  </span>
                </td>
                <td>
                  <span class="text-no-wrap">
                  {{result.result.pos_category}}
                    <span v-if="result.result.pos_category && result.result.intermediate_results[modality.intermediates.length - 1]">
                        <v-icon v-if="result.result.intermediate_results[result.result.intermediate_results.length - 1].pos_category > result.result.pos_category"
                                icon="fa-solid fa-caret-up" size="x-small" color="success"></v-icon>
                        <v-icon v-else-if="result.result.intermediate_results[result.result.intermediate_results.length - 1].pos_category < result.result.pos_category"
                                icon="fa-solid fa-caret-down" size="x-small" color="error"></v-icon>
                      <v-icon v-else icon="fa-solid fa-caret-left" size="x-small" color="grey"></v-icon>
                    </span>
                  </span>
                </td>
              </tr>
              </tbody>
            </v-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-btn class="mt-2" variant="elevated" color="primary" v-print="'#print_diploma'" block v-if="result && result.result && result.result.status == 'finished'">Imprimir diploma</v-btn>

  </v-container>

</template>

<style scoped>
.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: auto!important;
  display: flex!important;
  align-items: center!important;
}
>>>.v-img__img--cover {
  filter: blur(5px)!important;
}
</style>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import router from "@/router";
import Field from "@/components/Field.vue";
import MychipMap from "@/components/MychipMap.vue";

export default defineComponent({
  name: 'IndividualResultView',
  data: () => ({
    now: null,
    loading_result: true,
    result_id: null,
    result: null,
    last_intermediate: null,
    last_intermediate_distance: null,
    event: null,
    modality: null,
    stats: null,
    new_time: {},
    new_time_ok: {},
    last_positions: null,
    circuit: null,
    circuit_score: null,
    loading_circuit: false,
    loading_score: false,
  }),
  created() {
    this.now = this.moment().utc(true)
    setInterval(() => {
      this.now = this.moment().utc(true)
    }, 1000)
    document.title = this.$root.app_name
    this.result_id = this.$route.params.result_id
    this.get_registration_data()
  },
  methods: {
    get_registration_data() {
      this.loading_result = true
      let payload = {
        id: this.result_id
      }
      let splited = this.result_id.split(/[_-]/)
      if ( splited.length == 2 ) {
        payload = {
          event: splited[0],
          bib: splited[1]
        }
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          payload
      ).then((response) => {
        this.result = response.data[0]
        this.modality = this.result.modality
        this.event = this.modality.event
        this.last_intermediate = -1
        this.last_intermediate_distance = 0
        if ( this.result.result ) {
          this.result.result.intermediate_results.forEach(r => {
            if (r.total_time > 0) {
              this.last_intermediate = r.order
              this.last_intermediate_distance = this.modality.intermediates[r.order].distance_from_start
            }
          })
        }
        this.result.modality.intermediates.forEach(r => {
          this.new_time[r.order] = ""
          this.new_time_ok[r.order] = false
        })
        this.loading_result = false
        document.title = this.result.name + ' ' + this.result.surname + ' - ' + this.event.name_short || this.event.name +  ' - ' + this.modality.short_name || this.modality.name
        this.get_stats()
        this.get_last_position()
        if ( this.modality.circuit_modality != null ) {
          this.get_circuit()
          this.get_score()
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    get_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          {
            modality: this.result.modality._id.$oid
          }
      ).then((response) => {
        this.stats = response.data
      })
    },
    get_circuit() {
      this.loading_circuit = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          {
            id: this.modality.event.circuit.$oid
          }
      ).then((response) => {
        this.circuit = response.data[0]
        this.loading_circuit = false
      })
    },
    get_score() {
      this.loading_score = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          {
            "circuit_race": this.modality._id.$oid,
            "circuit_ids": [this.result_id],
            "circuit_registrations": true,
            "include_modality": false
          }
      ).then((response) => {
        this.circuit_score = response.data[0]
        this.loading_score = false
      })
    },
    get_last_position() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/last-positions`,
          {
            registrations: [this.result._id.$oid],
            only_last: false,
          }
      ).then((response) => {
        this.last_positions = response.data
      })
    }
  },

  components: {MychipMap, Field},

  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  }

});
</script>
